import digitalData from 'digital-data';
import { BUTTON_SUBMISSION, OVERLAY, STASH } from '../constants';

export const recentPurchases = () => {
  const publishAnalytics = (ddoEvent) => {
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  };

  window.LIFE_CYCLE_EVENT_BUS.on('recentPurchases.image-click', () => {
    const ddoEvent = {
      category: {
        primaryCategory: STASH,
      },
      eventInfo: {
        eventName: 'my account settings',
      },
      eventMode: STASH,
      stash: {
        myAccount: {
          settings: 'homepage: recent purchases tab: image click'
        },
      },
    };
    publishAnalytics(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('recentPurchases.edit-po-click', () => {
    const ddoEvent = {
      category: {
        primaryCategory: OVERLAY,
      },
      eventInfo: {
        eventName: 'homepage edit po job name',
      }
    };
    publishAnalytics(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('recentPurchases.save-po-click', () => {
    const ddoEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION,
      },
      eventInfo: {
        eventName: 'my account settings',
      },
      stash: {
        myAccount: {
          settings: 'homepage: recent purchases tab: po job name save'
        },
      },
    };
    publishAnalytics(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('recentPurchases.view-all-orders-click', () => {
    const ddoEvent = {
      category: {
        primaryCategory: STASH,
      },
      eventInfo: {
        eventName: 'my account settings',
      },
      eventMode: STASH,
      stash: {
        myAccount: {
          settings: 'homepage: recent purchases: view all orders'
        },
      },
    };
    publishAnalytics(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('recentPurchases.view-order-arrow-click', () => {
    const ddoEvent = {
      category: {
        primaryCategory: STASH,
      },
      eventInfo: {
        eventName: 'my account settings',
      },
      eventMode: STASH,
      stash: {
        myAccount: {
          settings: 'homepage: recent purchases: view order arrow'
        },
      },
    };
    publishAnalytics(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('recentPurchases.add-receipt-click', () => {
    const ddoEvent = {
      category: {
        primaryCategory: OVERLAY,
      },
      eventInfo: {
        eventName: 'homepage add receipt',
      }
    };
    publishAnalytics(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('recentPurchases.add-receipt-submit-click', () => {
    const ddoEvent = {
      category: {
        primaryCategory: BUTTON_SUBMISSION,
      },
      eventInfo: {
        eventName: 'my account settings',
      },
      stash: {
        myAccount: {
          settings: 'homepage: recent purchases: add receipt submit'
        },
      },
    };
    publishAnalytics(ddoEvent);
  });
};