import digitalData from 'digital-data';
import { getState } from '../analytics-state';
import { PIP_SUB_CONTENT, INTERACTION, COLLECTION } from '../constants';

export const mediaGallery = () => {
  const determineClickTarget = (target) => {
    if (target.match(/hotspots/i)) return 'hotspots';
    if (target.match(/image/i)) return 'image';
    if (target.match(/video/i)) return 'video';
    if (target.match(/threesixty/i)) return 'threesixty';
    if (target.match(/augmented_reality/i)) return 'ar';
    return target;
  };

  window.LIFE_CYCLE_EVENT_BUS.on('media-gallery.click', ({ output = {} }) => {
    // perform ddo stuff for carousel click
    const { pageType = null } = getState() || {};
    const isCollection = pageType === COLLECTION;

    if (!isCollection) {
      const {
        target = '', section, component, position, guid
      } = output;

      const ddoEvent = {
        eventInfo: {
          eventName: PIP_SUB_CONTENT
        },
        category: {
          primaryCategory: INTERACTION
        },
        subContent: {
          section,
          component,
          target: determineClickTarget(target),
          position,
          // ES6 syntactic sugar to add the guid property for images only
          ...(determineClickTarget(target) === 'image' && { guid })
        }
      };
      digitalData.event.push(ddoEvent);
    }
  });

  window.LIFE_CYCLE_EVENT_BUS.on('media-gallery.thumbnail_click', ({ output }) => {
    const { pageType = null } = getState() || {};
    const isCollection = pageType === COLLECTION;

    const {
      eventName,
      primaryCategory
    } = output;
    const thdAnalyticsEvent = {
      eventInfo: {
        eventName
      },
      category: {
        primaryCategory
      },
    };

    if (isCollection) {
      thdAnalyticsEvent.eventInfo.eventName = 'collection image';
    }

    digitalData.event.push(thdAnalyticsEvent);
  });
};