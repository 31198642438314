/* eslint-disable no-plusplus */
let traceId = '0';
if (typeof window !== 'undefined' && window.crypto && window.crypto.randomUUID) {
  traceId = window.crypto.randomUUID().split('-').join('');
}
let spanId = 0;
export const getClientTraceId = () => {
  /**
   * OpenTelemetry Context Propagator is expecting a strict pattern for x-cloud-trace-context. which is
   * (?<traceid>[0-9a-f]{32})\/(?<spanid>[\d]{1,20});o=(?<sampled>\d+)
   * so appending the ";o=1" to the traceid so it  will
   * be propogated to the API.Instead of writing custom text formater for the
   * traceid in each API application layer easier to append it here.
   */
  return `${traceId}/${spanId++};o=1`;
};
