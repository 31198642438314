import digitalData from 'digital-data';
import { getState } from '../../../analytics-state';
import {
  getABtest,
  getCategoryBreadCrumbs,
  getDomainList,
  getLoadEvent,
  getPageName,
  getPageType,
  getProductAnalytics,
  getProductBadges,
  getSSRError,
  setAnalytics,
  setAnalyticsNotExchange,
} from '../../../thd-analytics-utils';
import { MAGIC_PIP, NUCLEUS, ONLINE_STORE, PIP } from '../../../constants';
import * as util from './util.pip.analytics';

const initPIPAnalytics = (product, getPIPRedirectAnalytics) => {
  if (!product) return;
  let { location, experienceName } = getState();
  if (!experienceName) {
    experienceName = window?.experienceMetadata?.name;
  }

  const { info = {}, pricing = {} } = product || {};

  let pageTemplate = 'default';
  if (experienceName === 'major-appliances') {
    pageTemplate = 'appliance|default';
  }

  if (experienceName === 'rent-online') {
    pageTemplate = 'rental|default';
  }

  const hasApplianceExperienceQueryString = location?.search?.indexOf('exp=appliance') > -1;
  if (hasApplianceExperienceQueryString) {
    pageTemplate = 'appliance|v1';
  }
  digitalData.event = digitalData.event || [];
  digitalData.event.length = 0;

  if (digitalData?.content?.product) {
    digitalData.content.product = null;
  }
  let resultsTypeVal = '';
  if (window?.location?.href.includes('searchType=barcode')) {
    resultsTypeVal = 'barcode';
  }

  if (info?.protectionPlanSku) {
    digitalData.event.push(util.getHdppDisplayedPreloadEvent());
  }

  // COME BACK TO THIS -------------------------

  let serviceAddons;
  if (info?.hasServiceAddOns) {
    serviceAddons = product?.serviceAddOns;
  }

  digitalData.product = getProductAnalytics({ product, serviceAddons });

  // COME BACK TO THIS -------------------------

  // if (product?.details?.collection) {
  //   const collectionDetail = yield selectState('dataSources.collectionDetail', { timeout: 3000 });

  //   if (collectionDetail) {
  //     digitalData.content = {
  //       ...digitalData.content,
  //       product: (collectionDetail.products || []).map((prod, index) => ({
  //         itemId: prod.identifiers?.itemId,
  //         index
  //       }))
  //     };
  //   }
  // }

  digitalData.content = getProductBadges({
    digitalData,
    pricing,
    product,
  });

  const experienceTag = pricing?.promotion?.experienceTag;
  const featureName = (experienceTag === 'BOGO' && 'bogo')
    || (experienceTag === 'MSBONLINE' && 'multi-sku bulk')
    || (experienceTag === 'BMSM' && 'buy more save more')
    || '';

  if (featureName) {
    const contentModule = {
      name: 'buy box',
      feature: featureName,
      type: 'cta'
    };
    if (!Array.isArray(digitalData.content?.module)) {
      digitalData.content.module = [contentModule];
    } else {
      digitalData.content.module.push(contentModule);
    }
  }

  let isSSRError = false;
  if (typeof window.__SSR_ERROR !== 'undefined' && window.__SSR_ERROR) {
    isSSRError = true;
  }

  let pageError = isSSRError ? getSSRError() : [];

  if (digitalData?.page?.error) {
    pageError = pageError.concat(digitalData?.page?.error);
  }

  const { breadCrumbs = [] } = product?.taxonomy || {};

  digitalData.page = {
    pageInfo: {
      pageName: getPageName({ experienceName, postfix: product?.identifiers?.itemId }),
      pageLoadEvent: getLoadEvent(),
      abTest: getABtest({ experienceName, product }),
      pageType: getPageType({ experienceName }),
      pageTemplate,
      platform: NUCLEUS
    },
    onsiteSearchInfo: (Object.keys(getPIPRedirectAnalytics).length > 0)
      ? getPIPRedirectAnalytics : { resultsType: resultsTypeVal },
    category: getCategoryBreadCrumbs(breadCrumbs) || {},
    error: pageError
  };

  setAnalyticsNotExchange(digitalData);
};

export const pip = () => {
  let hasInitedPip = false;

  LIFE_CYCLE_EVENT_BUS.on('thd-analytics.state-change', ({ output = {} }) => {
    if (hasInitedPip) { return; }
    const {
      experienceName,
      storeId,
      dataSources,
      pageItemId,
      pageType,
      previousPage
    } = output;
    if (
      !(pageType === PIP || pageType === MAGIC_PIP)
      && pageType !== 'tool:productrental:reservation'
      && pageType !== 'tool:productrental:pip'
    ) { return; }
    const product = dataSources?.product?.[pageItemId] || {};
    const domains = getDomainList(experienceName);
    let keys = Object.keys(product);
    if (product) {
      keys = keys.concat(Object.keys(product) || {});
    }
    keys = keys.filter((key) => key !== '__typename');
    const hasDomains = domains.every((domain) => keys.indexOf(domain) > -1);
    const needsServiceAddOns = product?.info?.hasServiceAddOns;
    const hasServiceAddOns = needsServiceAddOns ? !!product?.serviceAddOns : true;

    if (hasDomains && hasServiceAddOns && storeId !== ONLINE_STORE && product?.__typename === 'BaseProduct') {
      hasInitedPip = true;
      if (previousPage === undefined && document.referrer.indexOf('/p/') === -1) {
        if (performance.navigation.type === 0) {
          if (localStorage.qm_search_term && window?.location?.pathname.includes(localStorage.qm_search_term)) {
            const getPIPRedirectAnalytics = {
              experience: window?.experienceMetadata?.name,
              searchTerm: product?.identifiers?.itemId,
              correctedTerm: '',
              searchMedium: 'redirect',
              searchTypeAhead: '',
              semanticSearchTokens: '',
              searchIntent: 'default',
              resultType: 'search results'

            };
            initPIPAnalytics(product, getPIPRedirectAnalytics);
          } else {
            initPIPAnalytics(product, {});
          }
        } else {
          initPIPAnalytics(product, {});
        }
      } else {
        initPIPAnalytics(product, {});
      }
    }
  });

  LIFE_CYCLE_EVENT_BUS.on('router.change', (routerChangeEvent) => {
    let { location } = getState();
    hasInitedPip = !util.hasPathNameChanged({ location, routerChangeEvent });
  });

  /* Removing due to race condition of double calls when routing from PLP to PIP
    May want to revisit and have a larger change for the `thd-analytics.router-change` event
  */
  // LIFE_CYCLE_EVENT_BUS.on('thd-analytics.router-change', () => {
  //   hasInitedPip = false;
  // });

  window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('default-4xx.load-pip', ({ output } = {}) => {
    const pageType = getPageType();
    digitalData.page = {
      pageInfo: {
        pageLoadEvent: 'error',
        pageName: 'productNotFound',
        pageType,
        platform: NUCLEUS
      },
      error: [
        {
          type: 'advisory',
          displayType: 'page',
          pageSection: pageType,
          errorCode: '',
          // eslint-disable-next-line max-len
          message: 'The product you are trying to view is not currently available. If you have any questions, you can reach our web support team by email at customercare@homedepot.com or by phone at 1-800-430-3376'
        }
      ]
    };
    digitalData.product = [{
      productInfo: {}
    }];
    setAnalytics(digitalData);
  });
};
