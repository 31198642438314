import digitalData from 'digital-data';
import { NOT_APPLICABLE } from '../constants';

export const ratingsReviews = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('ratings-reviews.accordion-open', () => {
    digitalData.event = digitalData.event || [];
    digitalData.event.push({
      category: {
        primaryCategory: 'interaction'
      },
      eventInfo: {
        eventName: 'pip sub-content'
      },
      subContent: {
        component: 'accordion',
        section: 'zone-b',
        target: 'customer-reviews'
      },
    });
  });

  window.LIFE_CYCLE_EVENT_BUS.on('ratings-reviews.event', ({ output = {} }) => {
    let componentName = '';
    let elementName = '';
    let moduleName = '';

    const { origin, location, value } = output;
    switch (location) {
    case 'search': {
      componentName = 'search reviews';
      elementName = NOT_APPLICABLE;
      moduleName = origin;
      break;
    }
    case 'filter': {
      componentName = 'filter reviews';
      elementName = value || NOT_APPLICABLE;
      moduleName = origin;
      break;
    }
    case 'writereview': {
      componentName = 'write a review';
      elementName = NOT_APPLICABLE;
      moduleName = origin;
      break;
    }
    case 'writereviewoverlay': {
      componentName = 'write a review overlay';
      elementName = value || NOT_APPLICABLE;
      moduleName = origin;
      break;
    }
    case 'keyword': {
      componentName = 'keyword';
      elementName = value;
      moduleName = origin;
      break;
    }
    case 'sort': {
      componentName = 'sort by';
      elementName = value;
      moduleName = origin;
      break;
    }
    case 'badge': {
      componentName = 'badge';
      elementName = NOT_APPLICABLE;
      moduleName = origin;
      break;
    }
    case 'media': {
      componentName = 'media';
      elementName = value;
      moduleName = origin;
      break;
    }
    case 'readmore': {
      componentName = 'read more';
      elementName = NOT_APPLICABLE;
      moduleName = origin;
      break;
    }
    case 'helpful': {
      componentName = 'was this helpful';
      elementName = value;
      moduleName = origin;
      break;
    }
    case 'profile': {
      componentName = 'profile';
      elementName = NOT_APPLICABLE;
      moduleName = origin;
      break;
    }
    case 'pagination': {
      componentName = 'pagination';
      elementName = NOT_APPLICABLE;
      moduleName = origin;
      break;
    }
    case 'starrating': {
      componentName = 'star rating';
      elementName = NOT_APPLICABLE;
      moduleName = origin;
      break;
    }
    case 'sentiment': {
      componentName = value;
      elementName = NOT_APPLICABLE;
      moduleName = origin;
      break;
    }
    case 'zone a': {
      componentName = value;
      elementName = NOT_APPLICABLE;
      moduleName = 'zone a';
      break;
    }
    default: {
      componentName = 'search reviews';
      elementName = NOT_APPLICABLE;
      moduleName = origin;
    }
    }

    digitalData.event = digitalData.event || [];
    digitalData.event.push({
      category: {
        primaryCategory: 'button submission'
      },
      eventInfo: {
        eventName: 'ugc click'
      },
      content: {
        ugc: [{
          module: moduleName,
          component: componentName,
          element: elementName
        }]
      }
    });
  });

  window.LIFE_CYCLE_EVENT_BUS.on('ratings-reviews.form-error', ({ output = {} }) => {
    const { message, code } = output;
    digitalData.event = digitalData.event || [];
    digitalData.event.push({
      category: {
        primaryCategory: 'error'
      },
      eventInfo: {
        eventName: 'post page error',
        error: [{
          type: 'error',
          displayType: 'inline',
          pageSection: 'write a review',
          message,
          code
        }]
      }
    });
  });
};