import { getState } from './analytics-state';
import {
  SEARCH,
  BROWSE,
  PIP,
  PEP,
  COMPARE,
  CONTENT,
  STOREFINDER,
  COLLECTION,
  NOT_APPLICABLE,
  PAGE_DEFAULT_TEMPLATE,
  INSPIRATIONAL,
  PAGE_AB_TEST,
  SEARCH_PAGE_AB_TEST,
  DEFAULT_BROWSE_CATEGORY_AB_TEST_L1,
  DEFAULT_BROWSE_CATEGORY_AB_TEST_L2,
  PAGINATION,
  NO_EVENT,
  NO_FILTER,
  SSKU,
  NOT_CUSTOM_CATALOG,
  BADGE_MODELS_NEW,
  BADGE_MODELS_SEASONAL,
  BADGE_MODELS_EXCLUSIVE,
  BADGE_MODELS_TRENDING,
  BADGE_MODELS_BEST_SELLER,
  BADGE_MODELS_CUSTOM,
  BADGE_MODELS_GUARANTEED,
  BADGE_MODELS_POPULAR,
  NON_BUYABLE_REQUEST_A_QUOTE,
  NON_BUYABLE_REQUEST_A_QUOTE_PAGE_TYPE,
  RENT_ONLINE,
  RENT_ONLINE_PIP_PAGE,
  RENT_ONLINE_RESERVATION_PAGE,
  DEFAULT_PRODUCT_DOMAINS,
  APPLIANCE_PRODUCT_DOMAINS,
  RENTAL_PRODUCT_DOMAINS,
  RENTAL_RESERVATION_DOMAINS,
  MAJOR_APPLIANCE,
  B2B_MAJOR_APPLIANCE,
  DUAL_PATH_AB_TEST,
  NON_BUYABLE_SERVICES,
  NON_BUYABLE_SERVICES_PRODUCT_DOMAINS,
  AUTOMOTIVE,
  BUYITAGAIN,
  MY_LIST_SHARED,
  MY_LIST_DETAILS,
  GIFT_CARDS,
  BDS,
  MAGIC_PIP
} from './constants';

const isDualPathSku = (product = {}) => product?.serviceAddOns?.sellFurnishInstall?.dualPathSku;

const getStoreLocation = ({ fulfillment, isAnchor }) => {
  const locations = fulfillment?.locations || [];
  return locations.find((location) => location?.isAnchor === isAnchor);
};

const isRequestAQuoteServiceExperience = () => {

  const { experienceName } = getState() || {};

  return experienceName === NON_BUYABLE_REQUEST_A_QUOTE;
};

const isNonBuyableServicesExperience = () => {

  const { experienceName } = getState() || {};

  return experienceName === NON_BUYABLE_SERVICES;
};

const isRentOnlineExperience = () => {

  const { experienceName } = getState() || {};

  return experienceName === RENT_ONLINE;
};

const isApplianceExperience = () => {

  const { experienceName } = getState() || {};

  return experienceName === MAJOR_APPLIANCE || experienceName === B2B_MAJOR_APPLIANCE;
};

const isAutomotiveExperience = () => {
  const { experienceName } = getState() || {};

  return experienceName === AUTOMOTIVE;
};

export const getPageType = ({ path = window?.location?.pathname, experienceName = null } = {}) => {
  if (experienceName === NON_BUYABLE_REQUEST_A_QUOTE) {
    return NON_BUYABLE_REQUEST_A_QUOTE_PAGE_TYPE;
  }

  const updatedPath = path.replace(/^\/render[^/]*/, '');

  if (/^\/s\//.test(updatedPath) || (/^\/b\//.test(updatedPath) && /Ntt-/.test(updatedPath))) {
    return SEARCH;
  }

  if (/^\/p\/paint\/config\//.test(updatedPath)) {
    return MAGIC_PIP;
  }

  if (/^\/p\//.test(updatedPath)) {
    if (/^\/p\/rental\/reservations\//.test(updatedPath)) {
      return RENT_ONLINE_RESERVATION_PAGE;
    }
    if (/^\/p\/rental\//.test(updatedPath)) {
      return RENT_ONLINE_PIP_PAGE;
    }
    return PIP;
  }

  if (/^\/pep\//.test(updatedPath)) {
    return PEP;
  }

  if (/^\/b\//.test(updatedPath)) {
    return BROWSE;
  }

  if (/^\/c\//.test(updatedPath)) {
    return CONTENT;
  }

  if (/^\/l\//.test(updatedPath)) {
    return STOREFINDER;
  }

  if (/^\/collection\//.test(updatedPath)) {
    return COLLECTION;
  }

  if (/^\/compare\//.test(updatedPath)) {
    return COMPARE;
  }

  if (/^\/buyitagain/.test(updatedPath)) {
    return BUYITAGAIN;
  }

  if (/^\/list\/view\/details\/shared\//.test(updatedPath)) {
    return MY_LIST_SHARED;
  }

  if (/^\/list\/view\/details\//.test(updatedPath)) {
    return MY_LIST_DETAILS;
  }

  if (/^\/gift-cards\//.test(updatedPath)) {
    return GIFT_CARDS;
  }

  if (/^\/bath-renovation/.test(updatedPath)) {
    return BDS;
  }
  return 'unknown';
};

export const getPageName = ({ postfix, experienceName }) => {

  if (isRequestAQuoteServiceExperience()) {
    return `leadgen>${postfix}`;
  }

  if (isRentOnlineExperience()) {
    let path = window.location.pathname;
    const updatedPath = path.replace(/^\/render[^/]*/, '');
    if (/^\/p\/rental\/reservations\//.test(updatedPath)) {
      return `product rental>${postfix}>reservation`;
    }
    return `product rental>${postfix}`;
  }

  if (getPageType() === PIP) {
    return `productDetails>${postfix}`;
  }

  return '';
};

export const getLoadEvent = () => {

  if (isRequestAQuoteServiceExperience()) {
    return '';
  }

  if (isRentOnlineExperience()) {
    let path = window.location.pathname;
    const updatedPath = path.replace(/^\/render[^/]*/, '');
    if (/^\/p\/rental\/reservations\//.test(updatedPath)) {
      return 'reservation view';
    }
    return 'rental view';
  }

  return 'product view';
};

export const getDomainList = () => {

  if (isRequestAQuoteServiceExperience()) {
    return [];
  }
  if (isApplianceExperience()) {
    return APPLIANCE_PRODUCT_DOMAINS;
  }
  if (isRentOnlineExperience()) {
    let path = window.location.pathname;
    const updatedPath = path.replace(/^\/render[^/]*/, '');
    if (/^\/p\/rental\/reservations\//.test(updatedPath)) {
      return RENTAL_RESERVATION_DOMAINS;
    }
    return RENTAL_PRODUCT_DOMAINS;
  }
  if (isNonBuyableServicesExperience()) {
    return NON_BUYABLE_SERVICES_PRODUCT_DOMAINS;
  }

  return DEFAULT_PRODUCT_DOMAINS;
};

export const getPageTemplate = (experienceName) => {
  switch (experienceName) {
  case 'hd-home':
    return INSPIRATIONAL;
  default:
    return PAGE_DEFAULT_TEMPLATE;
  }
};

export const getABtest = ({
  experienceName,
  breadCrumbs,
  isCategory,
  product,
}) => {
  if (experienceName === 'hd-home') {
    return PAGE_AB_TEST;
  }

  if (experienceName === 'rent-online') {
    return '';
  }

  if (experienceName === 'general-merchandise') {

    if (isDualPathSku(product)) {
      return DUAL_PATH_AB_TEST;
    }

    if (getPageType() === PIP) return '';

    if (!isCategory) {
      return SEARCH_PAGE_AB_TEST;
    }

    if (isCategory && breadCrumbs?.siteSection === breadCrumbs?.contentSubCategory) {
      return DEFAULT_BROWSE_CATEGORY_AB_TEST_L1;
    }
    return DEFAULT_BROWSE_CATEGORY_AB_TEST_L2;
  }

  if (getPageType() === SEARCH || getPageType() === BROWSE) {
    return SEARCH_PAGE_AB_TEST;
  }

  return '';
};

export const Fulfillment = {
  isBODFS: (props) => {
    return !!Fulfillment.bodfs(props);
  },
  bodfs: ({ fulfillmentOptions }) => {
    const deliveryService = (fulfillmentOptions || []).find((option) => option.type === 'delivery');
    const expressFulfillment = (deliveryService?.services || []).find((service) => service.type === 'express delivery');
    return expressFulfillment || null;
  },
  isBOPIS: (props) => {
    return !!Fulfillment.bopis(props);
  },
  bopis: ({ fulfillmentOptions }) => {
    const pickupService = (fulfillmentOptions || []).find((option) => option.type === 'pickup');
    const bopisFulfillment = (pickupService?.services || []).find((service) => service.type === 'bopis');
    return bopisFulfillment || null;
  },
  isBOSS: (props) => {
    return !!Fulfillment.boss(props);
  },
  boss: ({ fulfillmentOptions }) => {
    const pickupService = (fulfillmentOptions || []).find((option) => option.type === 'pickup');
    const bossFulfillment = (pickupService?.services || []).find((service) => service.type === 'boss');
    return bossFulfillment || null;
  },
  isCurrentlyUnavailable: ({ fulfillmentOptions }) => {
    return !((fulfillmentOptions || [])
      .find((option) => (option.services || [])
        .find((service) => (service.locations || [])
          .find((location) => location.inventory?.isInStock))));
  },
  isOutOfStockOnline: ({ fulfillmentOptions }) => {
    const deliveryService = (fulfillmentOptions || []).find((option) => option.type === 'delivery');
    const shippingFulfillment = (deliveryService?.services || []).find((service) => service.type === 'sth');
    const locations = shippingFulfillment?.locations;
    if (locations?.length) {
      return !!(locations[0]?.inventory?.isOutOfStock);
    }
    if (!fulfillmentOptions) {
      return true;
    }
    return false;
  },
  isSTH: (props) => {
    return !!Fulfillment.shipToHome(props);
  },
  shipToHome: ({ fulfillmentOptions }) => {
    const deliveryService = (fulfillmentOptions || []).find((option) => option.type === 'delivery');
    const shippingFulfillment = (deliveryService?.services || []).find((service) => service.type === 'sth');
    return shippingFulfillment || null;
  },
  isFulfillable: ({ fulfillmentOptions }) => {
    return !!(fulfillmentOptions || []).find((option) => option.fulfillable);
  },
  storeType: ({ fulfillmentOptions }) => {
    const pickupService = (fulfillmentOptions || []).find((option) => option.type === 'pickup');
    const bopisFulfillment = (pickupService?.services || []).find((service) => service.type === 'bopis');
    if (bopisFulfillment && bopisFulfillment?.locations?.length > 1) {
      return 'nearby';
    }
    return 'anchor';
  }
};

const transformFulfillmentToOldDS = (fulfillment) => {
  return {
    ...fulfillment,
    isBODFS: Fulfillment.isBODFS(fulfillment),
    bodfs: Fulfillment.bodfs(fulfillment),
    isBOPIS: Fulfillment.isBOPIS(fulfillment),
    bopis: Fulfillment.bopis(fulfillment),
    isBOSS: Fulfillment.isBOSS(fulfillment),
    boss: Fulfillment.boss(fulfillment),
    isCurrentlyUnavailable: Fulfillment.isCurrentlyUnavailable(fulfillment),
    isOutOfStockOnline: Fulfillment.isOutOfStockOnline(fulfillment),
    isSTH: Fulfillment.isSTH(fulfillment),
    shipToHome: Fulfillment.shipToHome(fulfillment),
    isFulfillable: Fulfillment.isFulfillable(fulfillment),
    storeType: Fulfillment.storeType(fulfillment)
  };
};

export const getStoreAvailabilityStatus = (fulfillment, availabilityType) => {
  let store = {
    boss: null,
    bopis: null
  };
  const skuStatus = (fulfillment?.anchorStoreStatusType !== 'NA'
    && fulfillment?.anchorStoreStatusType?.toLowerCase()?.replace('_', ' '))
   || NOT_APPLICABLE;
  const pickupService = (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
  if (!fulfillment?.fulfillmentOptions && availabilityType?.type === 'Shared') {
    store.bopis = 'oos';
  }
  const isBossOOS = (!fulfillment?.fulfillmentOptions && availabilityType?.type === 'Online')
    || (availabilityType?.type === 'Shared'
      && fulfillment?.shipToHome?.locations?.[0]?.type === 'online'
      && !fulfillment?.shipToHome?.locations?.[0]?.inventory?.isInStock);

  if (isBossOOS && !availabilityType?.discontinued) {
    store.boss = 'oos';
  }
  if (fulfillment?.isBOPIS) {
    const bopisFulfillment = fulfillment?.bopis || {};
    const nearbyStoreLocation = getStoreLocation({ fulfillment: bopisFulfillment, isAnchor: false });
    const localStoreLocation = getStoreLocation({ fulfillment: bopisFulfillment, isAnchor: true });
    if (!pickupService?.fulfillable) {
      store.bopis = 'oos';
    } else if (nearbyStoreLocation) {
      if (nearbyStoreLocation?.inventory?.isInStock) {
        store.bopis = 'available';
      }
      if (nearbyStoreLocation?.inventory?.isLimitedQuantity) {
        store.bopis = 'limited quantity';
      }
    } else if (localStoreLocation) {
      store.bopis = 'available';
      if (localStoreLocation?.inventory?.isLimitedQuantity) {
        store.bopis = 'limited quantity';
      }
      if (localStoreLocation?.inventory?.isOutOfStock) {
        store.bopis = 'oos';
      }
    }
  }
  if (fulfillment?.isBOSS) {
    const bossFulfillment = fulfillment?.boss || {};
    store.boss = 'available';
    if (bossFulfillment?.inventory?.isOutOfStock) {
      store.boss = 'oos';
    }
  }
  return { ...store, skuStatus };
};

export const getShippingAvailabilityStatus = (fulfillment, availabilityType) => {
  let shipping = {
    sth: null,
    bodfs: null
  };
  const skuStatus = (fulfillment?.onlineStoreStatusType !== 'NA'
    && fulfillment?.onlineStoreStatusType?.toLowerCase()?.replace('_', ' '))
    || NOT_APPLICABLE;
  const type = availabilityType?.type;
  if (fulfillment?.isBODFS) {
    shipping.bodfs = 'displayed';
  }
  if (!fulfillment?.fulfillmentOptions && !availabilityType?.discontinued && (type === 'Online' || type === 'Shared')) {
    shipping.sth = 'oos';
  }
  if (fulfillment?.isSTH) {
    const sthService = fulfillment?.shipToHome;
    const locations = sthService?.locations;
    shipping.sth = 'available';
    if (locations.length > 0 && locations[0]?.inventory?.isOutOfStock) {
      shipping.sth = 'oos';
    }
  }
  return { ...shipping, skuStatus };
};

export const getStoreAndShippingAvailabilityStatus = (originalFulfillment, availabilityType) => {
  const fulfillment = transformFulfillmentToOldDS(originalFulfillment || {});

  let type = availabilityType?.type?.split(' ')?.[0]?.toLowerCase();

  if (type === 'browse'
  && fulfillment?.fulfillmentOptions?.[0]?.type === 'pickup'
  && fulfillment?.bopis?.locations?.[0]?.type === 'store') {
    type = 'store';
  }
  return {
    store: getStoreAvailabilityStatus(fulfillment, availabilityType),
    shipping: getShippingAvailabilityStatus(fulfillment, availabilityType),
    type
  };
};

export const getAvailabilityStatus = ({ product = {} }) => {
  const fulfillment = transformFulfillmentToOldDS(product?.fulfillment || {});
  const availabilityType = product?.availabilityType || {};
  const isSubscriptionEnabled = product?.subscription?.subscriptionEnabled || false;
  const storeStatus = getStoreAvailabilityStatus(fulfillment, availabilityType);
  const shippingStatus = getShippingAvailabilityStatus(fulfillment, availabilityType);

  let availabilityStatus = {
    type: availabilityType?.type?.toLowerCase(),
    backOrder: fulfillment?.backordered ? 'yes' : 'no',
    subscription: isSubscriptionEnabled
  };

  if (storeStatus) {
    availabilityStatus = {
      ...availabilityStatus,
      store: storeStatus
    };
  }
  if (shippingStatus) {
    availabilityStatus = {
      ...availabilityStatus,
      shipping: shippingStatus
    };
  }

  return availabilityStatus;
};

export const getContentProducts = (products = [], pageType = '') => {
  return products?.map((prod, index) => {
    const {
      itemId,
      fulfillment,
      availabilityType,
      badges,
      info: {
        isSponsored
      } = {},
      pricing
    } = prod || {};

    let allProductBadges = [];

    if (badges) {
      const topBadgeName = badges?.[0]?.name;
      if (topBadgeName) {
        allProductBadges.push({
          type: 'event',
          name: topBadgeName,
          recommendationStrategy: NOT_APPLICABLE,
          recommendationVersion: NOT_APPLICABLE
        });
      }

    }

    if (pricing?.preferredPriceFlag) {
      allProductBadges.push({
        type: 'price',
        name: 'preferred pricing',
        recommendationStrategy: NOT_APPLICABLE,
        recommendationVersion: NOT_APPLICABLE
      });
    }

    let compatibility;
    if (isAutomotiveExperience()) {
      compatibility = prod?.fitment?.doesFit === 'yes' ? 'y' : 'n';
    }

    return {
      productInfo: {
        sku: itemId,
        position: index + 1,
        availabilityStatus: pageType === PEP
          ? getAvailabilityStatus({ product: prod })
          : getStoreAndShippingAvailabilityStatus(fulfillment, availabilityType),
        storeType: Fulfillment.storeType(fulfillment || {}) || 'not localized',
        badge: allProductBadges,
        sponsored: !!isSponsored,
        customProduct: NOT_CUSTOM_CATALOG,
        ...(compatibility && { compatibility })
      },
    };
  });
};

export const makeEverythingLowerCase = (obj) => {
  let localObj = obj;
  Object.keys(localObj).forEach((key) => {
    if (typeof localObj[key] === 'string') {
      localObj[key] = localObj[key].toLowerCase();
    }
  });
  return localObj;
};

export const setAnalytics = (digitalData) => {
  const _ddo = digitalData.init();

  window._T = window._T || {};

  if (typeof window._T.pageData === 'undefined') {
    window._T.pageData = [];
  }
  window._T.pageData.push(_ddo);
};

export const getSSRError = () => {
  const pageErrors = [];
  if (typeof window.__SSR_ERROR !== 'undefined' && window.__SSR_ERROR) {
    const {
      error,
      stack
    } = window.__SSR_ERROR;
    const ssrError = {
      type: 'error',
      displayType: 'page',
      pageSection: stack,
      message: error,
      code: null,
      output: window.__SSR_ERROR
    };
    pageErrors.push(ssrError);
  }
  return pageErrors;
};

export const getETA = ({ product }) => {
  const fulfillment = transformFulfillmentToOldDS(product?.fulfillment || {});
  const eta = {};

  if (fulfillment.isBOSS && fulfillment.boss?.deliveryDates?.startDate) {
    eta.bossStartDate = fulfillment.boss?.deliveryDates?.startDate;
    eta.bossEndDate = fulfillment.boss?.deliveryDates?.endDate || eta.bossStartDate || '';
  }
  if (fulfillment.isSTH && fulfillment.shipToHome?.deliveryDates?.startDate) {
    eta.sthStartDate = fulfillment.shipToHome?.deliveryDates?.startDate;
    eta.sthEndDate = fulfillment.shipToHome?.deliveryDates?.endDate || eta.sthStartDate;
  }

  return eta;
};

export const getProductAnalytics = ({ product, serviceAddons }) => {
  const state = getState();
  const { sskuInteraction, superDuperSkuInteraction, pageType = '' } = state || {};

  let productsArray = [];

  if (isRequestAQuoteServiceExperience()) {
    return productsArray;
  }
  const badges = product?.badges || [];
  const identifiers = product?.identifiers || {};
  const info = product?.info || {};
  const pdepartment = info?.productDepartment || '';
  const pclass = info?.classNumber || '';
  const psubclass = info?.subClassNumber || '';
  const {
    itemId: sku,
    productType,
    productLabel: productName,
    brandName: manufacturer = 'unbranded'
  } = identifiers;

  const fulfillment = transformFulfillmentToOldDS(product?.fulfillment || {});

  const _product = {
    productInfo: {
      sku,
      productType,
      productName,
      manufacturer,
      department: pdepartment,
      class: pclass,
      subclass: psubclass,
      badge: badges.map((bdg) => ({ type: bdg.label, recommendationVersion: 'n/a', recommendationStrategy: 'n/a' })),
      availabilityStatus: getAvailabilityStatus({ product }),
      storeType: fulfillment.storeType || 'not localized',
      sskuInteraction: sskuInteraction || false,
      superDuperSkuInteraction: superDuperSkuInteraction || false,
      customProduct: NOT_CUSTOM_CATALOG,
    }
  };

  const eventBadges = badges.filter((badge) => {
    return badge.label && [BADGE_MODELS_NEW,
      BADGE_MODELS_SEASONAL,
      BADGE_MODELS_EXCLUSIVE,
      BADGE_MODELS_TRENDING,
      BADGE_MODELS_BEST_SELLER,
      BADGE_MODELS_CUSTOM,
      BADGE_MODELS_GUARANTEED,
      BADGE_MODELS_POPULAR].indexOf(badge.label) < 0;
  });
  if (eventBadges && eventBadges[0]) {
    _product.price = {
      ..._product.price,
      badge: {
        type: eventBadges[0].label.toLowerCase(),
        recommendationVersion: 'n/a',
        recommendationStrategy: 'n/a'
      }
    };
  }

  if (fulfillment?.isBOPIS && product?.fulfillment?.inStoreAssemblyEligible) {
    _product.productInfo.assembly = 'in store';
  }

  if (pageType === PEP) {
    _product.productInfo.type = product?.availabilityType?.type?.toLowerCase() || '';
  }

  const { installSkus = [] } = serviceAddons?.attachLabor || {};
  let isInstallationSku;
  if (installSkus?.length) {
    const serviceType = installSkus[0]?.serviceType?.toLowerCase() || '';
    if (fulfillment?.isSTH) {
      let assemblyProduct = {
        productInfo: {
          laborType: serviceType === 'install' ? 'installation' : `sellable ${serviceType}`
        }
      };
      isInstallationSku = installSkus[0]?.installSku;
      if (pageType === PEP) {
        assemblyProduct.productInfo.installationSku = installSkus[0]?.installSku;
      } else {
        assemblyProduct.productInfo.sku = installSkus[0]?.installSku;
      }
      productsArray.push(assemblyProduct);
    }
  }

  const isProAssembly = (!!serviceAddons?.proReferrals?.[0]?.assemblyCategory);
  const isProReferral = !isInstallationSku && (!!serviceAddons?.proReferrals && !isProAssembly);
  if (isProReferral) {
    const proReferralProduct = {
      productInfo: {
        laborType: 'proreferral',
        sku: _product.productInfo.sku
      }
    };
    productsArray.push(proReferralProduct);

  }

  const eta = getETA({ product });

  if (Object.keys(eta).length) {
    _product.productInfo.eta = eta;
  }
  productsArray.unshift(_product);
  return productsArray;
};

export const getHeaderCrumb = (breadCrumbs = []) => {
  return breadCrumbs?.map((breadCrumb) => breadCrumb?.label)?.join('>');
};

export const getCurrentPage = (startIndex, pageSize, defaultPageSize = 0) => {
  if (defaultPageSize) {
    return Math.floor(startIndex / defaultPageSize) + 1;
  }
  return (startIndex && pageSize) ? Math.round(startIndex / (pageSize - 1)) + 1 : 1;
};

export const shouldReInitAnalytics = (pageTransitionEvent) => {
  return pageTransitionEvent === NO_EVENT
    || pageTransitionEvent === PAGINATION
    || pageTransitionEvent === SSKU;
};

export const getFilters = ({ appliedDimensions = [] }) => {
  let appliedFilters = [];
  if (appliedDimensions && appliedDimensions.length) {
    (appliedDimensions || []).forEach((dimension) => {
      (dimension?.refinements || []).forEach((refinement, index) => {
        appliedFilters.push({
          position: `default:default:${index}`,
          selectionMethod: 'default',
          type: dimension?.label,
          value: refinement?.label
        });
      });
    });
  }
  if (!appliedFilters.length) {
    appliedFilters.push({
      selectionMethod: NO_FILTER,
      type: NO_FILTER,
      value: NO_FILTER,
      position: NO_FILTER
    });
  }
  return appliedFilters;
};

export const getCategoryBreadCrumbs = (breadCrumbs = []) => {
  const noCrumb = 'Unknown';
  const formattedBreadCrumbs = breadCrumbs?.[0]?.label === 'Home' ? breadCrumbs.slice(1) : breadCrumbs;
  const primaryCategory = formattedBreadCrumbs?.[0]?.label || noCrumb;
  const subCategory1 = formattedBreadCrumbs?.[1]
    ? `${primaryCategory}>${formattedBreadCrumbs?.[1]?.label || noCrumb}`
    : primaryCategory;
  const categoryCrumb = breadCrumbs?.[0]?.dimensionName
    ? breadCrumbs.filter((crumb) => crumb?.dimensionName.toLowerCase() === 'category') : breadCrumbs;
  const subCategory2 = getHeaderCrumb(categoryCrumb);

  return {
    primaryCategory,
    subCategory1,
    subCategory2
  };
};
export const getCategoryBreadCrumbsForArticles = (breadCrumbs = []) => {
  const chopOffFirstBreadCrumb = breadCrumbs[0]?.label === 'DIY Projects & Ideas' ? breadCrumbs.slice(1) : breadCrumbs;
  const primaryCategory = 'article';
  const pageName = 'alp';
  const subCategory1 = chopOffFirstBreadCrumb[1] ? `${primaryCategory}>${pageName}>${chopOffFirstBreadCrumb[0]?.label}`
    : `${primaryCategory}>${pageName}>${chopOffFirstBreadCrumb[0]?.label}`;
  const breadCrumbsLength = breadCrumbs.length;
  const subCategory2 = breadCrumbsLength <= 2 ? subCategory1
    : `${primaryCategory}>${pageName}>${chopOffFirstBreadCrumb[0]?.label}>${chopOffFirstBreadCrumb[1]?.label}`;

  return {
    primaryCategory,
    subCategory1,
    subCategory2
  };

};
export const getCategoryBreadCrumbsForALP = (breadCrumbs = []) => {
  const chopOffFirstBreadCrumb = breadCrumbs[0]?.label === 'DIY Projects & Ideas' ? breadCrumbs.slice(1) : breadCrumbs;
  const primaryCategory = 'article';
  const pageName = 'alp';
  const subCategory1 = chopOffFirstBreadCrumb[1]
    ? `${primaryCategory}>${pageName}>${chopOffFirstBreadCrumb[0]?.label}`
    : `${primaryCategory}>${pageName}>${chopOffFirstBreadCrumb[0]?.label}`;
  const breadCrumbsLength = breadCrumbs.length;
  const subCategory2 = breadCrumbsLength <= 2
    ? subCategory1
    : `${primaryCategory}>${pageName}>${chopOffFirstBreadCrumb[0]?.label}>${chopOffFirstBreadCrumb[1]?.label}`;

  return {
    primaryCategory,
    subCategory1,
    subCategory2,
  };
};
export const getCategoryBreadCrumbsForArticle = (breadCrumbs = []) => {
  const primaryCategory = breadCrumbs[0]?.label || 'DIY Projects & Ideas';
  const subCategory1 = breadCrumbs
    .slice(0, breadCrumbs.length - 1)
    .map((crumb) => crumb?.label)
    .join('>');
  const subCategory2 = breadCrumbs.map((crumb) => crumb?.label).join('>');

  return {
    primaryCategory,
    subCategory1,
    subCategory2,
  };
};

export const isDuplicatePageError = (digitalData, pageError) => {
  const isDupe = digitalData?.page?.error?.some(
    (err) => err?.pageSection?.length === pageError?.pageSection?.length
      && err?.message === pageError?.message
  );
  return isDupe;
};

export const isDuplicateEventError = (digitalData, pageErrors) => {
  const isDupe = digitalData?.event?.some(
    (err) => err?.eventInfo?.error?.[0]?.message === pageErrors?.[0]?.message
      && err?.eventInfo?.error?.[0]?.pageSection === pageErrors?.[0]?.pageSection
  );
  return isDupe;
};

function getEventBadge(badges) {
  const itemBadges = badges
    .filter((badge) => { return badge?.name === 'Event Badge'; });
  return itemBadges?.[0];
}

export function getProductBadges({ digitalData, pricing, product }) {
  let digitalDataBadgeInfo = digitalData?.content?.product?.productInfo?.badge || [];
  let allProductBadges = [...digitalDataBadgeInfo];

  if (product?.badges) {
    const eventBadge = getEventBadge(product.badges);
    if (eventBadge) {
      allProductBadges.push({
        type: 'event',
        name: eventBadge.label.toLowerCase(),
      });
    }
  }

  if (pricing?.preferredPriceFlag) {
    allProductBadges.push({
      type: 'price',
      name: 'preferred pricing'
    });
  }

  let content = { ...digitalData?.content };

  if (allProductBadges.length) {
    content = {
      ...digitalData?.content,
      product: {
        ...digitalData?.content?.product,
        productInfo: {
          ...digitalData?.content?.product?.productInfo,
          badge: allProductBadges,
        },
      },
    };
  }

  return content;
}

export const pathToPageSection = (path) => {
  let pageSection;
  if (path.indexOf('ProductPage[0]') > -1) {
    pageSection = 'pip';
  } else if (path.indexOf('PepPage[0]') > -1) {
    pageSection = 'pep';
  } else {
    pageSection = path;
  }
  return pageSection;
};

export const isDeliveryService = (fulfillmentOption) => {
  return fulfillmentOption?.type === 'delivery';
};

export const setAnalyticsNotExchange = (digitalData) => {
  const allowedDomains = window.cookieUtils?.readBrowserCookie('allowed_domains');
  if (!allowedDomains) {
    setAnalytics(digitalData);
  }
};

export const isOutOfStockOnlinePod = (product = {}) => {
  const { dataSources, fulfillment, availabilityType } = product;
  const deliveryService = (fulfillment?.fulfillmentOptions || []).find(isDeliveryService);
  const shippingFulfillment = (deliveryService?.services || []).find((service) => service.type === 'sth');
  const locations = shippingFulfillment?.locations;
  if (dataSources === 'catalog' && !fulfillment?.fulfillmentOptions && availabilityType?.type === 'Online') {
    return true;
  }
  if (locations?.length) {
    return locations[0]?.inventory?.isOutOfStock && !deliveryService?.fulfillable;
  }
  return false;
};

export const isDiscontinued = (product) => {
  return !!(product?.availabilityType?.discontinued);
};

export const initializeFiltersState = ({ appliedDimensions = [] }) => {
  let { filters = [] } = getState();
  if (appliedDimensions) {
    if (appliedDimensions.length > 0 && filters.length === 0) {
      (appliedDimensions || []).forEach((dimension) => {
        (dimension?.refinements || []).forEach((refinement, index) => {
          filters.push({
            position: `default:default:${index}`,
            selectionMethod: 'default',
            dimensionName: dimension?.label,
            label: refinement?.label
          });
        });
      });
    }
  }
  return filters;
};

export const formatCarPickerString = (data, delimiter) => {
  return data
    ?.filter(function (item) { return item === 0 || item; })
    ?.join(delimiter)
    ?.trim();
};
