import digitalData from 'digital-data';
import { INTERACTION, PIP_SUB_CONTENT, OVERLAY, GEN_MERCH_BUNDLE } from '../constants';
import { getState } from '../analytics-state';

export const swapitem = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('swap-item.success', () => {
    const { packageCategory } = getState() || {};
    digitalData.event = digitalData.event || [];
    if (packageCategory === 'gm') {
      digitalData.event.push({
        category: {
          primaryCategory: OVERLAY
        },
        eventInfo: {
          eventName: GEN_MERCH_BUNDLE
        }
      });
    } else {
      const DDOEvent = {
        category: {
          primaryCategory: INTERACTION,
        },
        eventInfo: {
          eventName: PIP_SUB_CONTENT,
        },
        subContent: {
          component: 'swap',
          position: 'n/a',
          section: 'package',
          target: 'drawer',
        },
      };
      digitalData.event.push(DDOEvent);
    }
  });

  window.LIFE_CYCLE_EVENT_BUS.on('swap-item.error', ({ output = {} }) => {
    const DDOEvent = {
      category: {
        primaryCategory: 'error'
      },
      eventInfo: {
        eventName: 'post page error'
      }
    };
    const { message, code } = output;
    const isClientError = code >= 400 && code < 500;
    const errorObject = {
      type: isClientError ? 'error' : 'system',
      displayType: 'inline',
      pageSection: 'swap drawer',
      message,
      code
    };
    if (Array.isArray(DDOEvent.eventInfo.error)) {
      DDOEvent.eventInfo.error.push(errorObject);
    } else {
      DDOEvent.eventInfo.error = [errorObject];
    }
    digitalData.event = digitalData.event || [];
    digitalData.event.push(DDOEvent);
  });
};