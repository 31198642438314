import digitalData from 'digital-data';
import {
  INTERACTION,
  PIP_SUB_CONTENT,
  STASH,
  MODULE,
  ATC,
  CART_ADD_LOCATION,
  CART_ADDITION_INFO,
  NOT_APPLICABLE,
  CART_ADD
} from '../constants';
import { getState } from '../analytics-state';

const publishEventSubContent = ({ output } = {}) => {
  const { component, section, target } = output || {};

  if (!component || !section || !target) return;

  const DDO = {
    category: {
      primaryCategory: INTERACTION,
    },
    eventInfo: {
      eventName: PIP_SUB_CONTENT,
    },
    subContent: {
      component,
      section,
      target,
    }
  };

  digitalData.event = digitalData.event || [];
  digitalData.event.push(DDO);
};

const getItemsFromCartReqParams = (cartReqParams) => {
  if (Array.isArray(cartReqParams)) {
    return cartReqParams.map(({ itemId, displayPosition }) => (
      {
        productInfo: {
          sku: itemId,
        },
        displayPosition,
      }
    ));
  }
  return [{ productID: cartReqParams?.itemId, index: '' }];
};

const getCartStashEvent = (output = {}) => {
  const {
    cartReqParams,
    cartOptions,
  } = output;

  const { pageType = '' } = getState() || {};
  const section = MODULE;
  const target = ATC;
  const items = getItemsFromCartReqParams(cartReqParams);
  const eventName = Array.isArray(cartReqParams) ? CART_ADDITION_INFO : CART_ADD_LOCATION;
  const sharedSection = cartOptions?.sharedSection || NOT_APPLICABLE;
  const component = cartOptions?.component || '';

  return {
    eventInfo: {
      eventName
    },
    category: {
      primaryCategory: STASH
    },
    eventMode: STASH,
    stash: {
      component,
      item: items,
      pageType,
      section,
      sharedSection,
      target
    }
  };
};

const getCartAddEvent = (output = {}) => {
  const { cartInfo = {} } = output;

  return {
    category: {
      primaryCategory: 'add to cart click',
    },
    eventInfo: {
      eventName: CART_ADD,
    },
    cart: {
      cartId: cartInfo.cartId,
    },
    item: cartInfo.items,
  };
};

export const promotionProducts = () => {
  window.LIFE_CYCLE_EVENT_BUS.once('promotion-products-cta.click', publishEventSubContent);
  window.LIFE_CYCLE_EVENT_BUS.on('promotion-products-quickview-section.click', publishEventSubContent);
  window.LIFE_CYCLE_EVENT_BUS.on('promotion-products-summary-page.click', publishEventSubContent);

  window.LIFE_CYCLE_EVENT_BUS.on('promotion-products-add-to-cart.success', ({ output = {} }) => {
    const ddoStashEvent = getCartStashEvent(output);
    const ddoAddEvent = getCartAddEvent(output);

    // This is a digital data push func not a JS push so need to repeat twice.
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoStashEvent);
    digitalData.event.push(ddoAddEvent);
  });
};