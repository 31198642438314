import { route } from './experience/route';
import { dataSources } from './component/data-sources.listener';
import { rental } from './component/rental.listener';
import { layoutManager } from './component/layout-manager.listener';
import { errorBoundary } from './component/error-boundary.listener';
import { experienceSSR } from './experience/experience-ssr';
import { operationsServer } from './experience/operations';
import { customVariables } from './experience/custom-variables';
import { timeouts } from './experience/timeouts';
import { cartListener } from './component/cart.listener';
import { buyboxListener } from './component/buybox.listener';
import { performanceListener } from './component/performance.listener';
import { noResultsListener } from './component/no-results.listener';
import { shouldIgnoreBinding } from './utils/ignore';
import { tntListener } from './component/tnt.listener';
import { kpiListener } from './component/kpi.listener';
import { experienceHandler } from './experience/app-render.listener';
import { performanceObserver } from './experience/performance-observer';
import { componentSelectorListener } from './component/component-selector.listener';
import { invalidDataScenarioListener } from './component/invalid-data-scenario.listener';
import { proCustomerQuoteListener } from './component/proCustomerQuote.listener';
import { cookieSizeTracking } from './experience/cookie-size-tracking';

const NewRelicBridge = {
  init: () => {
    if (typeof window === 'undefined') return;
    if (typeof newrelic === 'undefined') return;

    try {
      // reports all api errors
      shouldIgnoreBinding();
      dataSources();
      // reports invalid data scenarios
      invalidDataScenarioListener();
      // reports all component errors that failed to layout
      layoutManager();
      errorBoundary();
      customVariables();
      experienceSSR();
      operationsServer();
      timeouts();
      cartListener();
      buyboxListener();
      performanceListener();
      noResultsListener();
      tntListener();
      kpiListener();
      experienceHandler();
      componentSelectorListener();
      proCustomerQuoteListener();
      // reports rental api calls and actions
      rental();
      // reports element attributes for CWVs tracking
      performanceObserver();
      // reports total cookie size and top cookies for errant cookie size tracking
      cookieSizeTracking();
    } catch (err) {
      console.log(err);
    }

  }
};

export { NewRelicBridge };
