import digitalData from 'digital-data';
import { getState } from '../analytics-state';
import { TABLEVIEW, TABLE } from '../constants';

export const productResults = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('product-results.select-list-layout', (newLayout = {}) => {
    const state = getState();
    const { pageType, currentPage, sortBy } = state || {};

    digitalData.event = digitalData.event || [];
    digitalData.event.push(
      {
        category: {
          primaryCategory: pageType || '',
        },
        eventInfo: {
          eventName: 'view type by',
        },
        displayAttributes: {
          pageNumber: currentPage || 0,
          productsAvailabilty: 'all products',
          sortBy: sortBy || 'bestmatch',
          viewType: newLayout.output || '',
        }
      }
    );
    if (digitalData?.page?.displayAttributes && newLayout?.output) {
      const viewType = newLayout?.output === TABLE ? TABLEVIEW : newLayout?.output;
      digitalData.page.displayAttributes.viewType = viewType;
    }
  });
};