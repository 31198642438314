import { defaultDataIdFromObject } from '@apollo/client';
import * as localOnlyFields from './localOnlyFields';
import { getQueryFromAttributes } from './dataModel/getQueryFromAttributes';

const URI = '/product-information/model';
const FEDERATION_URI = '/federation-gateway/graphql';
const DEFAULT_EXPIRATION = 60 * 60; // 1 hour in seconds

export const STORAGE_KEY = 'dsPersistedStorage';

export const getFullyQualifiedAPIPath = ({ host, federation = false }) => {
  if (federation) {
    return `${host}${FEDERATION_URI}`;
  }
  return `${host}${URI}`;
};

export const getRelativeAPIPath = ({ federation = false, host = '' } = {}) => {
  if (federation) {
    return `${host || ''}${FEDERATION_URI}`;
  }
  return `${host || ''}${URI}`;
};

const generateSearchReportId = ({ object }) => {

  const id = [];

  if (object.searchUrl) {
    id.push(object.searchUrl);
  }
  if (object.pageSize) {
    id.push(object.pageSize);
  }
  if (object.startIndex) {
    id.push(object.startIndex);
  }
  if (object.sortBy) {
    id.push(object.sortBy);
  }
  if (object.totalProducts) {
    id.push(object.totalProducts);
  }
  if (object.keyword) {
    id.push(object.keyword);
  }

  if (id.length) {
    return id.join('-');
  }
  return defaultDataIdFromObject(object);
};

const generateSearchId = ({ object }) => {
  if (object.id) return object.id;

  if (object.searchReport) {
    return generateSearchReportId({ object: object.searchReport });
  }
  return defaultDataIdFromObject(object);
};

export const dataIdFromObject = (object) => {
  const dataSource = object.dataSource || object.dataSources;
  // mocking does not provide typename
  // handle usecase of product so that it gets cached
  let typename = object.__typename;
  if (!typename) {
    if (dataSource && object.itemId) {
      if (dataSource === 'Collection') {
        typename = 'CollectionProduct';
      } else {
        typename = 'BaseProduct';
      }
    }
  }
  switch (typename) {

  case 'LoyaltyMembership':
    return object.svocID
      ? object.svocID
      : defaultDataIdFromObject(object);
  case 'Model':
    return object.itemId;
  case 'SearchModel':
    return generateSearchId({ object });
  case 'FbrProduct':
    return object.itemId || object.identifiers?.itemId
      ? `fbr-${object.itemId || object.identifiers?.itemId}`
      : defaultDataIdFromObject(object);
  case 'BaseProduct':
  case 'DynamicRecsProduct':
  case 'EndCapProduct':
    // toReference will create an id, so always return it if its there
    // @todo this should be done on other types but leaving for now.
    if (object.id) {
      return object.id;
    }
    if (dataSource === 'dynamicRecs' && (object.itemId || object.identifiers?.itemId)) {
      return `dynamicRecs-${object.itemId || object.identifiers?.itemId}`;
    }
    return (object.itemId || object.identifiers?.itemId)
      ? `base-${dataSource || 'catalog'}-${object.itemId || object.identifiers?.itemId}`
      : defaultDataIdFromObject(object);
  case 'CollectionProduct':
    return object.itemId || object.identifiers?.itemId
      ? `collection-${object.itemId || object.identifiers?.itemId}`
      : defaultDataIdFromObject(object);
  // case 'EndCapProduct':
  //   return object.itemId || object.identifiers?.itemId
  //     ? `base-${dataSource || 'topDeals'}-${object.itemId || object.identifiers?.itemId}`
  //     : defaultDataIdFromObject(object);
  case 'FBRItem':
    return object?.product?.itemId
      ? `fbr-wrapper${object?.product?.itemId}`
      : defaultDataIdFromObject(object);
  default:
    return defaultDataIdFromObject(object); // fall back to default handling
  }
};

const productFieldsToMerge = {
  ...localOnlyFields.Product,
  availabilityType: {
    merge: true
  },
  identifiers: {
    merge: true
  },
  details: {
    merge: true
  },
  fulfillment: {
    merge: true,
  },
  pricing: {
    merge: true
  },
  favoriteDetail: {
    merge: true
  },
  reviews: {
    merge: true
  },
  info: {
    merge: true
  },
  media: {
    merge: true
  },
  taxonomy: {
    merge: true
  },
  installServices: {
    merge: true
  },
  subscription: {
    merge: true
  },
  keyProductFeatures: {
    merge: true
  }
};

export const typePolicies = {
  Query: {
    fields: {
      topDeals: {
        merge: true
      },
      component: {
        read(existingData, opts) {
          return opts.toReference({
            __typename: opts.args.componentClass,
            id: opts.args.id,
          });
        }
      },
      product: {
        keyArgs: ['itemId'],
        read(existingData, {
          args,
          toReference,
          readField
        }) {
          // this cant be correct and never executes
          if (existingData) {
            const dataSource = existingData.dataSource || existingData.dataSources;
            const existingDataSource = readField('dataSource', existingData) || readField('dataSources', existingData);
            if (args.dataSource === 'FBR' && existingDataSource === 'FBR') {
              return existingData;
            } if (args.dataSource === 'dynamicRecs' && existingDataSource === 'dynamicRecs') {
              return existingData;
            } if ((args.dataSource === 'catalog' || !args.dataSource) && existingDataSource === 'catalog') {
              return existingData;
            } if (args.dataSource === 'Collection') {
              return existingData;
              // eslint-disable-next-line max-len
            } if (args.dataSource === 'searchNav' && (existingDataSource === 'searchNav' || dataSource === 'searchNav')) {
              // hovering on supersku swatch of product pod, kicks off
              return existingData;
            }
            // @todo other recommenders

          }

          switch (args.dataSource) {
          case 'dynamicRecs':
          case 'discoveryZones':
          case 'buyitagain':
            return toReference(dataIdFromObject({
              __typename: 'DynamicRecsProduct',
              id: args.id,
              itemId: args.itemId,
              dataSource: args.dataSource,
            }));
          case 'FBR':
            return toReference(dataIdFromObject({
              __typename: 'FbrProduct',
              id: args.id,
              itemId: args.itemId
            }));
          case 'Collection':
            return toReference(dataIdFromObject({
              __typename: 'CollectionProduct',
              id: args.id,
              itemId: args.itemId
            }));
          // case 'topDeals':
          //   return toReference(dataIdFromObject({
          //     __typename: 'EndCapProduct',
          //     id: args.id,
          //     itemId: args.itemId
          //   }));
          case 'relatedSearch':
            return toReference(dataIdFromObject({
              __typename: 'BaseProduct',
              id: args.id,
              itemId: args.itemId,
              dataSources: 'dynamicRecs'
            }));

          case 'searchNav':
            return toReference({
              __typename: 'BaseProduct',
              id: dataIdFromObject({
                __typename: 'BaseProduct',
                id: args.id,
                itemId: args.itemId,
                dataSources: 'searchNav'
              })
            });

          case 'packages':
            return toReference({
              __typename: 'BaseProduct',
              id: dataIdFromObject({
                __typename: 'BaseProduct',
                id: args.id,
                itemId: args.itemId,
                dataSources: 'packages'
              })
            });

          default:

            return toReference({
              __typename: 'BaseProduct',
              id: dataIdFromObject({
                __typename: 'BaseProduct',
                id: args.id,
                itemId: args.itemId,
                dataSources: args.dataSource
              })
            });
          }
        },
        merge(existing, incoming, opts) {
          if (!existing) {
            if (opts.args.dataSource) {
              return { ...incoming, dataSources: opts.args.dataSource };
            }

            return incoming;
          }
          const incomingItemId = opts.readField('itemId', incoming);
          const exstingItemId = opts.readField('itemId', existing);
          if (incomingItemId !== exstingItemId) return incoming;

          const incomingDS = opts.readField('dataSource', incoming) || opts.readField('dataSources', incoming);
          const existingDS = opts.readField('dataSource', existing) || opts.readField('dataSources', existing);

          if (incomingDS !== existingDS) {
            return incoming;
          }

          return opts.mergeObjects(existing, incoming);
        }
      },
      dynamicRecs: {
        merge: true
      },
    }
  },
  DynamicRecsProduct: {
    fields: productFieldsToMerge
  },
  BaseProduct: {
    fields: productFieldsToMerge
  },
  FbrProduct: {
    fields: productFieldsToMerge
  },
  CollectionProduct: {
    fields: productFieldsToMerge
  },
  Details: {
    fields: {
      collection: {
        merge: true
      }
    }
  },
  Pricing: {
    fields: {
      alternate: {
        merge: true
      },
      promotion: {
        merge: true
      }
    }
  },
  Bundle: {
    fields: {
      products: {
        merge: (existing, incoming, opts) => {
          // if irg has a lot of items it creates a perf bottleneck
          // irg needs to be refactored with pagination.
          return (incoming || []).slice(0, 18);
        }
      }
    }
  },
  Alternate: {
    fields: {
      unit: {
        merge: true
      }
    }
  },
  RatingsReviews: {
    fields: {
      alternate: {
        merge: true
      }
    }
  },
  Media: {
    fields: {
      ...localOnlyFields.Media
    }
  },
  AvailabilityType: {
    fields: {
      ...localOnlyFields.AvailabilityType
    }
  },
  Fulfillment: {
    fields: {
      ...localOnlyFields.Fulfillment
    }
  },
  Info: {
    fields: {
      ...localOnlyFields.Info
    }
  },
  Identifiers: {
    fields: {
      ...localOnlyFields.Identifiers
    }
  },
};

export const prepareQueryForCache = (queryInformation, queryOptions) => {
  const { data, variables, ssr } = queryOptions;
  const { attributes, queryName } = queryInformation;
  const query = getQueryFromAttributes({ name: queryName, attributes, ssr });

  return {
    query,
    data,
    variables
  };
};

export const getQueryKey = (queryName, variables) => {
  return `${queryName}-${JSON.stringify(variables)}`;
};

export const setNewDataInStorage = ({
  data,
  storage,
  queryKey,
  expirationInSeconds = DEFAULT_EXPIRATION,
  attributes,
  queryName,
  options
}) => {
  if (typeof window !== 'undefined') {
    const persistedData = JSON.parse(window[storage].getItem(STORAGE_KEY)) || {};

    /**
     * We only want to set data if not already in storage, otherwise we'll
     * be adding the same data back and we'll keep bumping out the expiration
     * so we could miss backend data changes (esp for localStorage).
     */
    if (!persistedData[queryKey]) {
      const newData = {
        ...persistedData,
        [queryKey]: {
          data,
          expiration: Date.now() + (expirationInSeconds * 1000),
          attributes,
          queryName,
          options
        }
      };
      window[storage].setItem(STORAGE_KEY, JSON.stringify(newData));
    }
  }
};

export const updateStorageAndClientCache = (client) => {
  if (typeof window === 'undefined') return;

  const storage = {
    sessionStorage: JSON.parse(window.sessionStorage.getItem(STORAGE_KEY)) || {},
    localStorage: JSON.parse(window.localStorage.getItem(STORAGE_KEY)) || {}
  };

  Object.entries(storage).forEach(([storeKey, storeData]) => {
    Object.entries(storeData).forEach(([queryKey, information]) => {
      const {
        attributes,
        data,
        expiration,
        options,
        queryName,
      } = information;

      if (expiration > Date.now()) {
        client.writeQueryToCache(
          { attributes, queryName },
          { ...options, data }
        );

      } else {
        // eslint-disable-next-line no-param-reassign
        delete storeData[queryKey];
      }
    });

    /**
     * Update browser storage, since we removed
     * expired items above from "storeData" above
     */
    window[storeKey].setItem(STORAGE_KEY, JSON.stringify(storeData));
  });
};
