/* eslint-disable max-len */
import digitalData from 'digital-data';
import { getState, setState } from '../../../analytics-state';
import {
  PLP_GRID,
  PRODUCTS_AVAILABILITY,
  NUCLEUS,
  BROWSE_RESULTS,
  PLP,
  PAGINATION,
  NO_EVENT,
  FILTER,
  SORT,
  TABLEVIEW
} from '../../../constants';
import {
  setAnalytics,
  makeEverythingLowerCase,
  getContentProducts,
  getABtest,
  getPageTemplate,
  getHeaderCrumb,
  getCurrentPage,
  shouldReInitAnalytics,
  getFilters,
  initializeFiltersState,
  setAnalyticsNotExchange
} from '../../../thd-analytics-utils';
import * as util from './util.browse.analytics';
import { getBrowseSearchBreadCrumb } from '../search/util.search.analytics';

export function browseAndCategory() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('browse.new-products', ({ output: browseModel }) => {
    if (!browseModel) return;

    const {
      searchReport = {},
      taxonomy = {},
      metadata = {},
      products = [],
      defaultPageSize = '',
      isTableView = false,
      isLocalized = null,
    } = browseModel;

    if (isLocalized === false) return;

    let { experienceName, pageTransitionEvent = NO_EVENT } = getState();
    if (!experienceName) {
      experienceName = window?.experienceMetadata?.name;
    }

    const onsiteSearchInfo = util.getOnsiteSearchInfo({ browseModel, experienceName });
    const breadCrumbs = getBrowseSearchBreadCrumb({ taxonomy });
    const noResults = onsiteSearchInfo?.numberOfResults === '0';
    const isCategory = util.isContentOnlyPage({ browseModel });
    let pageLoadEvent = BROWSE_RESULTS;
    let pageName = noResults && !isCategory ? 'no search results' : breadCrumbs?.contentSubCategory;
    let isHybrid = browseModel?.metadata?.hasPLPBanner;
    let pageType = PLP;

    const {
      startIndex,
      pageSize,
      sortBy
    } = searchReport || {};
    const categoryCrumb = taxonomy?.breadCrumbs?.[0]?.dimensionName
      ? taxonomy?.breadCrumbs.filter((crumb) => crumb?.dimensionName.toLowerCase() === 'category') : taxonomy?.breadCrumbs;

    const currentPage = getCurrentPage(startIndex, pageSize, defaultPageSize);
    if (pageTransitionEvent === PAGINATION) pageLoadEvent = PAGINATION;

    setState({
      ...getState(),
      pageTransitionEvent: undefined,
      pageSize,
      currentPage,
      sortBy,
      filters: initializeFiltersState({ appliedDimensions: browseModel?.appliedDimensions })
    });

    if (isHybrid) {
      pageType = 'cat plp';
    }

    if (isCategory) {
      pageLoadEvent = 'category plp';
      pageType = 'category';
    } else {
      digitalData.content = {
        ...digitalData.content,
        product: getContentProducts(products)
      };
    }

    if (!shouldReInitAnalytics(pageTransitionEvent)) {
      if (pageTransitionEvent === FILTER) {
        LIFE_CYCLE_EVENT_BUS.trigger('filters-refinements.publish', {
          breadCrumbs: taxonomy?.breadCrumbs,
          searchReport,
          metadata
        });
      } else if (pageTransitionEvent === SORT) {
        LIFE_CYCLE_EVENT_BUS.trigger('sort-refinements.publish');
      }
      return;
    }

    digitalData.page = {
      pageInfo: {
        abTest: getABtest({ experienceName, breadCrumbs, isCategory }),
        pageName,
        pageLoadEvent,
        pageType,
        pageTemplate: getPageTemplate(experienceName),
        platform: NUCLEUS
      },
      category: {
        headerCrumb: getHeaderCrumb(categoryCrumb),
        primaryCategory: breadCrumbs.siteSection,
        subCategory1: breadCrumbs.contentCategory,
        subCategory2: breadCrumbs.contentSubCategory
      },
      onsiteSearchInfo,
      displayAttributes: {
        productsAvailability: PRODUCTS_AVAILABILITY,
        pageNumber: currentPage,
        sortBy,
        viewType: isTableView ? TABLEVIEW : PLP_GRID
      },
      filter: getFilters({ appliedDimensions: browseModel?.appliedDimensions })
    };

    digitalData.page.pageInfo = makeEverythingLowerCase(digitalData.page.pageInfo);
    digitalData.page.category = makeEverythingLowerCase(digitalData.page.category);
    digitalData.page.onsiteSearchInfo = makeEverythingLowerCase(digitalData.page.onsiteSearchInfo);

    setAnalyticsNotExchange(digitalData);
  });

  window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('default-4xx.load-plp', () => {
    digitalData.page = {
      pageInfo: {
        pageName: 'Error 404',
        pageType: 'Error',
        abTest: 'error:version:gen2',
        pageLoadEvent: '',
        pageTemplate: 'default',
        platform: NUCLEUS
      },
      category: {
        primaryCategory: 'error',
        subCategory1: 'error',
        subCategory2: 'error',
        headerCrumb: ''
      },
      onsiteSearchInfo: util.getOnsiteSearchInfo(),
      error: [
        {
          type: 'system',
          displayType: 'page',
          pageSection: 'Browse',
          // eslint-disable-next-line max-len
          message: 'This could be due to one of the following reasons: An internal homedepot.com error occurred. The file or page you are looking for no longer exists. The URL that was typed is incorrect.',
          code: '404'
        }
      ]
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push({
      category: {
        primaryCategory: 'error'
      },
      displayAttributes: {
        sortBy: '',
        viewType: '',
        productsAvailability: '',
        pageNumber: ''
      },
      eventInfo: {
        eventName: ''
      },
      filter: [],
      eventMode: 'preload',
      isReported: true,
      sourceIndex: 0
    });
    setAnalytics(digitalData);
  });
}
