import { SEARCH_RESULTS } from '../../../constants';

export const getSpecialBuyAnalytics = ({ model }) => {
  const { dimensions = [], relatedResults } = model;
  const nValue = '7';

  const holidaySavings = (dimensions || []).map((dim) => {
    return (dim.refinements || [])
      .find((refinement) => refinement.refinementKey === nValue && !refinement.selected);
  }).find((dim) => dim);

  if (relatedResults?.visualNavContainsEvents && !relatedResults?.visualNavContainsEvents
    // TODO: I don't understand this, it wants it to be true and false??
    && holidaySavings && holidaySavings.refinementKey) {
    return { slot: 'savings' };
  }
  return false;
};

export const getOnsiteSearchInfo = ({ searchModel, experienceName }) => {
  let visualCategoryIds = (searchModel?.relatedResults?.visualNavs || [])
    .map((visualNav) => ({ slot: visualNav?.categoryId }));

  // When no promotions in the visnav and special buy exist in the refinements, push saving slot for HD Home.
  if (experienceName === 'hd-home' || experienceName === 'general-merchandise') {
    const specialBuyAnalytics = getSpecialBuyAnalytics({ model: searchModel });
    if (specialBuyAnalytics) visualCategoryIds.push(specialBuyAnalytics);
  }

  const correctedTerm = searchModel?.searchReport?.correctedKeyword || '';

  let resultsType = SEARCH_RESULTS;
  if (correctedTerm) {
    resultsType = 'spell correction';
  }
  if (window?.location?.href.includes('searchType=barcode')) {
    resultsType = 'barcode';
  }

  let onsiteSearchInfo = {
    correctedTerm,
    numberOfResults: searchModel?.searchReport?.totalProducts?.toString() || '0',
    resultsCategory: 'search all',
    resultsType,
    searchIntent: 'default',
    searchTerm: searchModel?.searchReport?.keyword || '',
    searchTypeAhead: '',
    semanticSearchTokens: searchModel?.metadata?.analytics?.semanticTokens || '',
    visualCategoryId: visualCategoryIds || []
  };

  // @todo this should be done differently
  if (visualCategoryIds.length) {
    onsiteSearchInfo.experience = 'hd home';
  }

  return onsiteSearchInfo;
};

export const getBrowseSearchBreadCrumb = ({ taxonomy }) => {
  let siteSection = '';
  let contentCategory = '';
  let contentSubCategory = '';

  const defaultBreadCrumb = (taxonomy?.breadCrumbs || []).filter((breadCrumb) => {
    return breadCrumb?.dimensionName === 'Category';
  });
  if (defaultBreadCrumb?.length) {
    siteSection = defaultBreadCrumb[0]?.label;
    if (defaultBreadCrumb.length > 1) {
      contentCategory = `${defaultBreadCrumb[0]?.label}>${defaultBreadCrumb[1]?.label}`;
      contentSubCategory = defaultBreadCrumb.map((crumb) => crumb?.label).join('>');
    } else {
      contentCategory = defaultBreadCrumb[0]?.label;
      contentSubCategory = defaultBreadCrumb[0]?.label;
    }
  }
  return {
    siteSection,
    contentCategory,
    contentSubCategory
  };
};