import digitalData from 'digital-data';
import { getState } from '../analytics-state';
import { STASH, NOT_APPLICABLE } from '../constants';

const eventOriginatedFromProductPodOrFbt = (path) => {
  return (path || [])
    .reverse()
    .find((name) => (
      name.match(/ProductPodDefaultMobile\[/i)
      || name.match(/ProductPodDefaultDesktop\[/i)
      || name.match(/ProductPodDesktop\[/i)
      || name.match(/ProductPodMobile\[/i)
      || name.match(/productpod\[/i)
      || name.match(/frequentlyboughttogether\[/i)));
};

const eventOriginatedFromPromotionProducts = (path) => {
  return !!(path || []).find((name) => name.match(/promotionproducts/i));
};

const isFromPackagePageOrPackageMini = (path) => {
  const packagePageRE = /packagepage/i;
  const packageMiniRE = /packagesmini/i;
  const isFromPackages = path.find((part = '') => (packagePageRE.test(part) || packageMiniRE.test(part)));
  return !!isFromPackages;
};

export const getSectionFromPath = ({ path, cartOptions }) => {
  const buyBoxRE = /buybox/i;
  const sampleBoxRE = /sample/i;
  const isFromBuyBox = (path || []).find((part = '') => buyBoxRE.test(part));
  const isSampleBox = (path || []).find((part = '') => sampleBoxRE.test(part));
  const isFromPackages = isFromPackagePageOrPackageMini(path);

  if (eventOriginatedFromPromotionProducts(path)) {
    return 'module';
  }
  if (isFromBuyBox) {
    return 'buybox';
  }
  if (isSampleBox) {
    return 'sample';
  }
  if (isFromPackages) {
    return cartOptions?.packageInfo?.packageName || 'n/a';
  }
  return 'unknown';
};

export const getTargetFromPath = ({ path }) => {
  const lowerCasePath = path.map((name) => (name || '').toLowerCase());
  const atcIndex = lowerCasePath.findIndex((part) => /^addtocart/i.test(part));
  return atcIndex > -1 ? 'atc' : '';
};

const getItemsFromCartReqParams = (cartReqParams, cartOptions, path) => {
  const state = getState();
  const recommendation = {
    recommendationType: cartOptions?.recommendationType || cartOptions?.sharedSection || NOT_APPLICABLE,
    pageType: state?.pageType,
    displayPosition: NOT_APPLICABLE,
    strategy: NOT_APPLICABLE,
    recommendationVersion: NOT_APPLICABLE,
    featureVersion: NOT_APPLICABLE,
    anchorProductSku: cartOptions?.anchorProductSku || NOT_APPLICABLE,
  };
  const isFromPackages = isFromPackagePageOrPackageMini(path);

  if (Array.isArray(cartReqParams)) {
    if (isFromPackages) {
      return cartReqParams.map(({ itemId, displayPosition = NOT_APPLICABLE }) => (
        {
          productInfo: {
            sku: itemId,
          },
          displayPosition,
          recommendation
        }
      ));
    }
    return cartReqParams.map(({ itemId, displayPosition }) => (
      {
        productInfo: {
          sku: itemId,
        },
        displayPosition,
      }
    ));
  }
  return [{ productID: cartReqParams?.itemId, index: '' }];
};

export const addToCartAnalytics = () => {
  // @todo logic for dpd atc click
  // https://github.homedepot.com/thd-olt-component/hdhome-analytics/blob/master/events/dpd.js#L35

  window.LIFE_CYCLE_EVENT_BUS.on('cart.add-to-cart', ({ output }) => {
    const {
      cartReqParams,
      cartOptions,
      path = []
    } = output;

    if (eventOriginatedFromProductPodOrFbt(path) && !eventOriginatedFromPromotionProducts(path)) return;

    const { pageType = '' } = getState() || {};

    digitalData.event = digitalData.event || [];
    if (pageType !== 'browse') {
      const section = getSectionFromPath({ path, cartOptions });
      const target = getTargetFromPath({ path });
      const items = getItemsFromCartReqParams(cartReqParams, cartOptions, path);
      const isSample = cartOptions?.section === 'order sample';
      const eventName = Array.isArray(cartReqParams) && !isSample ? 'cart addition info' : 'cart add location';
      const sharedSection = cartOptions?.sharedSection || 'n/a';
      const component = cartOptions?.component || '';

      const ddoStashEvent = {
        eventInfo: {
          eventName
        },
        category: {
          primaryCategory: STASH
        },
        eventMode: STASH,
        stash: {
          component,
          item: items,
          pageType,
          section,
          sharedSection,
          target
        }
      };
      digitalData.event.push(ddoStashEvent);
    }
  });
};