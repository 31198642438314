import digitalData from 'digital-data';
import { BUTTON_SUBMISSION, CHECK_AVAILABILITY } from '../constants';

const buildItems = (processedData) => {
  const {
    products = [],
    hasBackordered,
    hasOutOfStock,
    isUnavailable,
    zipCode
  } = processedData || {};

  return products.map(({ endDeliveryDate, rawDeliveryDate, itemId }) => {
    return {
      fulfillment: {
        method: 'appl',
        zip: zipCode,
      },
      productInfo: {
        availabilityStatus: {
          backOrder: hasBackordered,
          inStockAppl: !hasOutOfStock && !isUnavailable,
        },
        eta: {
          applStartDate: rawDeliveryDate,
          applEndDate: endDeliveryDate
        },
        isBundled: products.length > 1,
        sku: itemId,
      }
    };
  });
};

export const checkAvailability = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('check-availability.depot-direct-success', ({ output = {} }) => {
    const { processedData, autoCheck } = output;

    if (autoCheck) return;

    const DDO = {
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      eventInfo: {
        eventName: CHECK_AVAILABILITY
      },
      item: buildItems(processedData)
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(DDO);
  });
};