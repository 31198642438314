/* global newrelic */
import { setState, getState } from '../analytics-state';

const { take } = window.LIFE_CYCLE_EVENT_BUS.effects;

export const dataSources = () => {
  window.LIFE_CYCLE_EVENT_BUS.runEventSequence(function* dataSourceListener() {
    while (true) {
      const { output } = yield take('data-sources.data');
      const { variables, data, type } = output || {};
      if (type === 'product' && data?.product?.__typename === 'BaseProduct') {
        const { itemId, storeId } = variables || {};
        const originalState = getState();
        const pageItemId = originalState?.pageItemId || window.location?.pathname?.split('/')?.pop();
        if (data?.product?.itemId && data?.product?.itemId === pageItemId) {
          const updatedDataSources = {
            ...originalState?.dataSources,
            product: {
              ...originalState?.dataSources?.product,
              [data?.product?.itemId]: {
                ...originalState?.dataSources?.product?.[itemId],
                ...data?.product
              }
            }
          };
          setState({
            ...getState(),
            dataSources: updatedDataSources,
            storeId: storeId || originalState.storeId,
            pageItemId
          });
        }
      }
      if (type === 'product' && data?.serviceAddOns?.__typename === 'ServiceAddonsResponse') {
        const originalState = getState();
        if (variables?.itemId && variables?.itemId === originalState?.pageItemId) {
          const updatedDataSourcesAddOns = {
            ...originalState?.dataSources,
            product: {
              ...originalState?.dataSources?.product,
              [variables?.itemId]: {
                ...originalState?.dataSources?.product?.[variables?.itemId],
                serviceAddOns: data?.serviceAddOns,
              }
            }
          };
          setState({
            ...getState(),
            dataSources: updatedDataSourcesAddOns
          });
        }
      }
      if (data?.collection?.__typename === 'CollectionResponse') {
        const originalState = getState();
        if (variables?.collectionId) {
          const updatedDataSourcesCollection = {
            ...originalState?.dataSources,
            collection: {
              ...data?.collection,
              collectionId: variables?.collectionId
            }
          };
          setState({
            ...getState(),
            dataSources: updatedDataSourcesCollection
          });
        }
      }
    }
  });
};