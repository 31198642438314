import digitalData from 'digital-data';
import {
  STASH,
  ATC,
  SEARCH,
  BROWSE,
  NOT_APPLICABLE,
  COLLECTION_PAGETYPE_LIST_VIEW,
  COLLECTION_PAGETYPE_INTEGRATED_BRANDS,
  COLLECTION_PAGETYPE_SPACE,
  COLLECTION,
  SEARCH_RESULTS,
  INTERACTION,
  INSTANT_RECOMMENDATION,
  PLP,
  BUTTON_SUBMISSION,
  PRODUCT_POD,
  CUSTOM_CATALOG,
  NOT_CUSTOM_CATALOG,
  SPECIAL_BUY_BANNER,
  CURATION,
  VISUAL_RECS,
  LIKE,
  DISLIKE,
  SHOP_BY_ROOM_COLLECTION,
  CART_ADDITION_INFO,
  CART_ADD_LOCATION,
  RECS_CART_ADDITION_INFO,
  RECOMMENDATIONS,
  INSTANT_RECOMMENDATIONS_GRID,
  SEARCH_RECOMMENDATION,
  RECOMMENDATION_EVENTNAME,
  RECENTLY_VIEWED_EVENTNAME,
  RECOMMENDATION_VERSION,
  RECS_SCHEMA_LIST,
  SECTION,
  STRATEGY,
  CTL_RECOMMENDATIONS,
  MORE_IN_THIS_COLLECTION,
  CTL_RECOMMENDATION_VERSION,
  CTL_STRATEGY,
  MY_HOMEPAGE,
  SP,
  SPONSORED_RECOMMENDATION_PIP_LOAD,
  HOMEPAGE_SPONSORED,
  SPONSORED_RECOMMENDATION_CART_ADDITION,
  SPONSORED_PROD_REC,
  SUPPLEMENTAL_RECOMMENDATIONS,
  MY_LIST_DETAILS_PAGE_TYPE,
  TABLEVIEW, SWIPER_SLIDE,
  CATEGORY_PAGE,
  PACKAGES_PIP,
  GEN_MERCH_PACKAGE,
  BUY_IT_AGAIN,
  TODAYS_RECS,
  PRICE_DROP
} from '../constants';
import { getState } from '../analytics-state';
import { getCollectionPageType } from '../experience/pages/collections/collections';
import { isOutOfStockOnlinePod, isDiscontinued } from '../thd-analytics-utils';
// TODO split off adjacent calls such as badge, exc

const sponsoredTracking = (sponsoredClickBeacon) => {
  if (sponsoredClickBeacon) {
    const img = document.createElement('img');
    img.src = sponsoredClickBeacon;
    img.style = 'display: none';
    document.body.appendChild(img);
  }
};

const sponsoredClickThroughAnalytics = (sponsoredMetadata) => {
  const sponsoredPodClickThroughEvent = {
    category: {
      primaryCategory: 'interaction'
    },
    eventInfo: {
      eventName: 'content media click'
    },
    creativeID: null,
    placementID: sponsoredMetadata?.placementId,
    campaignID: sponsoredMetadata?.campaignId,
    slotID: sponsoredMetadata?.slotId
  };

  digitalData.event.push(sponsoredPodClickThroughEvent);
};

const getSharedRecsAnalytics = (output = {}) => {
  return {
    category: {
      primaryCategory: STASH
    },
    eventMode: STASH,

    stash: {
      recommendation: {
        recommendationType: 'sp',
        pageType: 'plp_pod_sponsored',
        displayPosition: output.podPosition,
        anchorProductSku: NOT_APPLICABLE,
        strategy: NOT_APPLICABLE,
        recommendationVersion: 'HDPlpPodSponsored',
        featureVersion: NOT_APPLICABLE,
        interval: NOT_APPLICABLE,
      },
    },
  };
};

const pipRecsAnalytics = (output = {}) => {
  const pipAnalytics = {
    eventInfo: {
      eventName: 'sponsored recommendation pip load'
    }
  };

  const recsAnalyticsEvent = Object.assign(pipAnalytics, getSharedRecsAnalytics(output));
  digitalData.event = digitalData.event || [];
  digitalData.event.push(recsAnalyticsEvent);
};

const cartRecsAnalytics = (output = {}) => {
  const { pageType = '' } = getState() || {};
  const cartAnalytics = {
    eventInfo: {
      eventName: 'sponsored recommendation cart addition'
    },
    stash: {
      component: PRODUCT_POD,
      item: output.items,
      pageType,
      section: SPONSORED_PROD_REC,
      sharedSection: NOT_APPLICABLE,
      target: ATC,
    },
  };

  const sharedAnalytics = getSharedRecsAnalytics(output);
  const analyticsEvent = Object.assign(cartAnalytics, sharedAnalytics);
  digitalData.event = digitalData.event || [];
  digitalData.event.push(analyticsEvent);
};

const collectionsAnalytics = (podAnchorSku = '') => {
  const collectionEvent = {
    category: {
      primaryCategory: STASH
    },
    eventInfo: {
      eventName: 'collections product click'
    },
    eventMode: STASH,
    stash: {
      product: {
        productInfo: {
          sku: podAnchorSku
        }
      },
      collection: {
        pageLocation: ''
      }
    }
  };

  digitalData.event = digitalData.event || [];
  digitalData.event.push(collectionEvent);
};

const visualRecommendationAnalytics = (output = {}) => {
  const {
    recommendationVersion,
    podPosition
  } = output;
  const { pageType } = digitalData?.page?.pageInfo;
  const visualRecEvent = {
    category: {
      primaryCategory: STASH
    },
    eventInfo: {
      eventName: 'recommendation pip load'
    },
    eventMode: STASH,
    stash: {
      recommendation: {
        recommendationType: 'vis sim',
        pageType,
        displayPosition: podPosition,
        anchorProductSku: NOT_APPLICABLE,
        strategy: NOT_APPLICABLE,
        recommendationVersion,
        featureVersion: NOT_APPLICABLE,
        interval: NOT_APPLICABLE
      }
    }
  };

  digitalData.event = digitalData.event || [];
  digitalData.event.push(visualRecEvent);
};

const completeTheLookRecommendationsClickAnalytics = (output = {}) => {
  const { podPosition = '' } = output;
  const { pageItemId = '', pageType = null } = getState() || {};
  const ddoEvent = {
    category: {
      primaryCategory: STASH
    },
    eventInfo: {
      eventName: 'recommendation pip load'
    },
    eventMode: STASH,
    stash: {
      component: PRODUCT_POD,

      recommendation: {
        recommendationType: 'ctl',
        pageType,
        displayPosition: podPosition,
        anchorProductSku: pageItemId,
        recommendedProduct: NOT_APPLICABLE,
        strategy: CTL_STRATEGY,
        recommendationVersion: RECOMMENDATION_VERSION,
        featureVersion: NOT_APPLICABLE,
        interval: NOT_APPLICABLE
      }
    }
  };

  digitalData.event = digitalData.event || [];
  digitalData.event.push(ddoEvent);
};

const completeTheLookRecommendationsATCAnalytics = (output = {}) => {
  const { items = [] } = output;
  const { pageItemId = '', pageType = null } = getState() || {};

  const ddoEvent = {
    category: {
      primaryCategory: STASH
    },
    eventInfo: {
      eventName: 'recommendation cart addition'
    },
    eventMode: STASH,
    stash: {
      component: PRODUCT_POD,
      pageType,
      section: SECTION,
      sharedSection: NOT_APPLICABLE,
      target: ATC,
      item: [
        {
          productInfo: {
            sku: items[0]?.productId
          },
          displayPosition: items[0]?.index,
          recommendation: {
            recommendationType: 'ctl',
            pageType,
            anchorProductSku: pageItemId,
            strategy: CTL_STRATEGY,
            recommendationVersion: CTL_RECOMMENDATION_VERSION,
            featureVersion: NOT_APPLICABLE,
            interval: NOT_APPLICABLE
          }
        }
      ],
    }
  };

  digitalData.event = digitalData.event || [];
  digitalData.event.push(ddoEvent);
};

const eventOriginatedFrom = (componentName, domPath) => {
  const reversedPath = domPath?.slice()?.reverse();
  const component = new RegExp(componentName, 'i');
  return reversedPath?.find((name) => name?.match(component));
};

const getRecommendationType = (path, scheme) => {
  if (eventOriginatedFrom(BUY_IT_AGAIN, path)) return 'reorder';
  if (eventOriginatedFrom(TODAYS_RECS, path)) return 'todays';
  if (eventOriginatedFrom(PRICE_DROP, path)) return 'pricedrop';
  return scheme?.split('-')[0];
};
const schemaName = (path) => {
  if (eventOriginatedFrom(RECOMMENDATIONS, path)
    || eventOriginatedFrom(SEARCH_RECOMMENDATION, path)
    || eventOriginatedFrom(INSTANT_RECOMMENDATIONS_GRID, path)
    || eventOriginatedFrom(SUPPLEMENTAL_RECOMMENDATIONS, path)) {
    const isSchema = path.some((arr) => RECS_SCHEMA_LIST.indexOf(arr) >= 0);
    if (isSchema) {
      const index = path.findIndex((item) => item.match(/div\[0\]#id=/g) || item.match(/section\[0\]#id=/g));
      return path[index].split('#id=')[1];

    }
    return null;
  }
  return null;
};

const anchorProduct = (path) => {
  const getSchemaName = schemaName(path);
  if (getSchemaName) {
    if (getSchemaName.includes('plpbrowse_multi') || getSchemaName.includes('plpsearch_multi')) {
      return getSchemaName.split('-')[1];
    } if (getSchemaName.includes('searchViewed')) {
      return getSchemaName.split('-')[2];
    } if (getSchemaName.includes('pipsem')) {
      return getSchemaName.split('-')[1];
    }
  }
  return null;
};

const recommendationAnalytics = (output = {}) => {
  const { podPosition, path } = output || {};
  const { pageItemId = '', pageType = '' } = getState() || {};
  const scheme = schemaName(path);
  const anchorProductSku = anchorProduct(path);
  const isHomepage = digitalData?.page.pageInfo.pageType === MY_HOMEPAGE;
  const recommendationEvent = {
    category: {
      primaryCategory: STASH
    },
    eventInfo: {
      eventName: RECOMMENDATION_EVENTNAME
    },
    eventMode: STASH,
    stash: {
      recommendation: {
        recommendationType: getRecommendationType(path, scheme),
        pageType,
        displayPosition: podPosition,
        anchorProductSku: pageItemId || anchorProductSku,
        featureVersion: NOT_APPLICABLE,
        strategy: STRATEGY,
        recommendationVersion: RECOMMENDATION_VERSION,
        recommendedProduct: NOT_APPLICABLE,
        interval: NOT_APPLICABLE
      }
    }
  };

  if (isHomepage) {
    recommendationEvent.stash.recommendation.pageType = MY_HOMEPAGE;
  }
  digitalData.event = digitalData.event || [];
  digitalData.event.push(recommendationEvent);
};

const fbtRecommendationAnalytics = (output = {}) => {
  const { podPosition } = output || {};
  const { pageItemId = '', pageType = '' } = getState() || {};
  const fbtRecommendationEvent = {
    category: {
      primaryCategory: STASH
    },
    eventInfo: {
      eventName: RECOMMENDATION_EVENTNAME
    },
    eventMode: STASH,
    isReported: true,
    stash: {
      recommendation: {
        recommendationType: 'fbt',
        pageType,
        displayPosition: podPosition,
        anchorProductSku: pageItemId,
        featureVersion: NOT_APPLICABLE,
        strategy: NOT_APPLICABLE,
        recommendationVersion: NOT_APPLICABLE,
        interval: NOT_APPLICABLE
      }
    }
  };
  digitalData.event = digitalData.event || [];
  digitalData.event.push(fbtRecommendationEvent);
};

const recentlyViewedAnalytics = (output = {}) => {
  const { podPosition, path } = output || {};
  const { pageItemId = '' } = getState() || {};
  const pageType = digitalData?.page?.pageInfo.pageType;
  const scheme = schemaName(path) || 'rv';
  const recentlyViewedEvent = {
    category: {
      primaryCategory: STASH
    },
    eventInfo: {
      eventName: RECENTLY_VIEWED_EVENTNAME
    },
    eventMode: STASH,
    stash: {
      recommendation: {
        recommendationType: scheme.split('-')[0],
        pageType,
        displayPosition: podPosition,
        anchorProductSku: pageItemId || NOT_APPLICABLE,
        featureVersion: NOT_APPLICABLE,
        strategy: STRATEGY,
        recommendationVersion: RECOMMENDATION_VERSION,
        recommendedProduct: NOT_APPLICABLE,
        interval: NOT_APPLICABLE
      }
    }
  };
  digitalData.event = digitalData.event || [];
  digitalData.event.push(recentlyViewedEvent);
};

const sponsoredCarouselAnalyticsClick = (output = {}) => {
  const getPodPosition = parseInt(
    eventOriginatedFrom(SWIPER_SLIDE,
      output.path)?.replace(/[^0-9]/g, ''),
    10) + 1 || undefined;
  const podPosition = JSON.stringify(getPodPosition) || {};
  const { pageItemId = '' } = getState() || {};
  const sponsoredCarouselClickEvent = {
    category: {
      primaryCategory: STASH
    },
    eventInfo: {
      eventName: SPONSORED_RECOMMENDATION_PIP_LOAD
    },
    eventMode: STASH,
    stash: {
      recommendation: {
        recommendationType: SP,
        pageType: HOMEPAGE_SPONSORED,
        displayPosition: podPosition,
        anchorProductSku: pageItemId,
        strategy: NOT_APPLICABLE,
        recommendationVersion: NOT_APPLICABLE,
        featureVersion: NOT_APPLICABLE,
        interval: NOT_APPLICABLE
      }
    }
  };
  digitalData.event = digitalData.event || [];
  digitalData.event.push(sponsoredCarouselClickEvent);
};

const sponsoredProductAtcClickEvent = (output = {},) => {
  const getPodPosition = parseInt(
    eventOriginatedFrom(SWIPER_SLIDE,
      output.path)?.replace(/[^0-9]/g, ''),
    10) + 1 || undefined;
  const podPosition = JSON.stringify(getPodPosition) || {};
  const productId = output.items[0].productId || {};
  const index = (output.items[0].index).toString() || {};
  const { pageItemId = '' } = getState() || {};
  const sponsoredProductAtc = {
    category: {
      primaryCategory: STASH
    },
    eventInfo: {
      eventName: SPONSORED_RECOMMENDATION_CART_ADDITION
    },
    eventMode: STASH,
    stash: {
      component: PRODUCT_POD,
      item: [{
        index,
        productId,
      }],
      pageType: digitalData?.page.pageInfo.pageType,
      section: SPONSORED_PROD_REC,
      sharedSection: NOT_APPLICABLE,
      target: ATC,
      recommendation: {
        recommendationType: SP,
        pageType: digitalData?.page.pageInfo.pageType,
        displayPosition: podPosition,
        anchorProductSku: pageItemId,
        strategy: NOT_APPLICABLE,
        recommendationVersion: NOT_APPLICABLE,
        featureVersion: NOT_APPLICABLE,
        interval: NOT_APPLICABLE
      }
    }
  };
  digitalData.event = digitalData.event || [];
  digitalData.event.push(sponsoredProductAtc);
};

const isIRG = (path) => {
  return eventOriginatedFrom('itemrelatedgroupsdesktop\\[', path)
    || eventOriginatedFrom('itemrelatedgroupsmobile\\[', path);
};

const projectShoppingAtcAnalytics = (output = {}) => {
  const { category, projectId } = output || {};
  const { pageType = '' } = getState() || {};

  const projectShoppingEvent = {
    category: {
      primaryCategory: STASH
    },

    eventInfo: {
      eventName: CART_ADDITION_INFO
    },

    eventMode: STASH,

    stash: {
      component: projectId,
      pageType,
      section: 'project shopping drawer',
      sharedSection: category,
      target: ATC
    }
  };

  digitalData.event = digitalData.event || [];
  digitalData.event.push(projectShoppingEvent);
};

export const productPod = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('product-pod.click', ({ output }) => {
    const {
      badges,
      podPageNumber,
      irgSectionName,
      podPosition,
      podType,
      podPageResults,
      podAction,
      podInteractionNumber,
      podAnchorSku,
      sponsoredProduct,
      sponsoredClickBeacon,
      sponsoredMetadata,
      target,
      path
    } = output || {};

    const isATCEvent = podAction === 'Add to cart';
    const opensInNewTab = target === '_blank';
    const isNewTabEvent = opensInNewTab && !isATCEvent;
    const { pageType = null, dataSources } = getState() || {};
    const isCollection = pageType === COLLECTION;
    const IRGClick = isIRG(path);
    const visualrecommendationsClick = eventOriginatedFrom('visualrecommendations\\[', path);
    const recentlyViewedClick = eventOriginatedFrom('recentlyviewed\\[', path);
    const sponsoredCarousel = eventOriginatedFrom('hp_sponsored_auth', path);
    const recommendationsClick = eventOriginatedFrom('recommendationsproductpodcarousel\\[', path);
    const fbtRecommendationsClick = eventOriginatedFrom('RecsFBTProductPodCarousel\\[', path);
    const instantRecommendationsClick = eventOriginatedFrom(INSTANT_RECOMMENDATIONS_GRID, path);
    const searchRecommendationsClick = eventOriginatedFrom(SEARCH_RECOMMENDATION, path);
    const completeTheLookRecommendationClick = eventOriginatedFrom(CTL_RECOMMENDATIONS, path);
    const supplementalRecsClick = eventOriginatedFrom(SUPPLEMENTAL_RECOMMENDATIONS, path);
    const { pageItemId = '' } = getState() || {};

    const ddoEvent = {
      category: {
        primaryCategory: isNewTabEvent ? BUTTON_SUBMISSION : STASH
      },
    };

    if (isNewTabEvent) {
      ddoEvent.eventInfo = {
        eventName: 'plp pod new tab'
      };
      ddoEvent.pod = {
        podPageNumber,
        podPosition,
        podType,
        podPageResults,
        podAction,
        podInteractionNumber,
        podAnchorSku: podAnchorSku || pageItemId,
        sponsoredProduct
      };
    } else if (IRGClick) {
      ddoEvent.eventInfo = {
        eventName: 'recommendation pip load'
      };
      ddoEvent.stash = {
        recommendation: {
          recommendationType: 'irg',
          pageType,
          displayPosition: podPosition,
          anchorProductSku: pageItemId,
          recommendedProduct: NOT_APPLICABLE,
          strategy: irgSectionName || NOT_APPLICABLE,
          recommendationVersion: NOT_APPLICABLE,
          featureVersion: NOT_APPLICABLE,
          interval: NOT_APPLICABLE
        }
      };
    } else if (recentlyViewedClick) {
      recentlyViewedAnalytics(output);
    } else if (sponsoredCarousel) {
      sponsoredCarouselAnalyticsClick(output);
    } else if (recommendationsClick || instantRecommendationsClick
      || searchRecommendationsClick || supplementalRecsClick) {
      recommendationAnalytics(output);
    } else if (fbtRecommendationsClick) {
      fbtRecommendationAnalytics(output);
    } else {
      ddoEvent.eventMode = STASH;
      ddoEvent.eventInfo = {
        eventName: 'product pod click'
      };
      const podCount = isCollection ? dataSources?.collection?.metadata?.productCount?.allProducts
        : podPageResults;
      ddoEvent.stash = {
        podPageNumber,
        podPosition,
        podType,
        podPageResults: podCount,
        podAction,
        podInteractionNumber,
        podAnchorSku: pageItemId || podAnchorSku
      };
      if (!isCollection) {
        ddoEvent.stash.sponsoredProduct = sponsoredProduct;
        ddoEvent.eventInfo = {
          eventName: 'product pod click'
        };
        ddoEvent.stash.customProduct = badges?.find(({ name } = {}) => { return name === 'cpo'; })
          ? CUSTOM_CATALOG
          : NOT_CUSTOM_CATALOG;
      }
    }
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);

    if (isCollection) {
      collectionsAnalytics(podAnchorSku);
    }

    if (visualrecommendationsClick) {
      visualRecommendationAnalytics(output);
    }

    if (completeTheLookRecommendationClick) {
      completeTheLookRecommendationsClickAnalytics(output);
    }

    if (sponsoredProduct && sponsoredClickBeacon && !isATCEvent) {
      sponsoredTracking(sponsoredClickBeacon);
      pipRecsAnalytics(output);
    }

    if (sponsoredProduct && sponsoredMetadata) {
      sponsoredClickThroughAnalytics(sponsoredMetadata);
    }
  });

  window.LIFE_CYCLE_EVENT_BUS.on('product-pod.add-to-cart', ({ output }) => {
    const {
      items = [{ productId: '', index: '' }], // changed from productID to productId for consistency
      irgSectionName = '',
      podType = null,
      path = [],
      section = 'gridview',
      sharedSection = PLP,
      sponsoredClickBeacon,
      sponsoredProduct,
      isTableView = false
    } = output || {};

    const extraEventData = {};
    const { pageType = null, pageItemId = '', dataSources } = getState() || {};
    const isCollection = pageType === COLLECTION;
    const isArticle = digitalData?.page.pageInfo.pageType === 'article';
    const isHomepage = digitalData?.page.pageInfo.pageType === MY_HOMEPAGE;
    const collectionType = dataSources?.collection?.metadata?.type;
    const isSearchOrBrowse = [SEARCH, BROWSE].indexOf(pageType) > -1;
    const isSpaceCollection = eventOriginatedFrom(SHOP_BY_ROOM_COLLECTION, path);
    const completeTheLookRecommendationClick = eventOriginatedFrom(CTL_RECOMMENDATIONS, path);
    const sponsoredProductAtcClick = eventOriginatedFrom('hp_sponsored_auth', path);
    const projectShoppingAtcClick = eventOriginatedFrom('ProjectShopping', path);
    const productShelfAtcClick = eventOriginatedFrom('ProductShelf', path);

    if (sponsoredProductAtcClick) {
      sponsoredProductAtcClickEvent(output);
      return;
    }

    if (projectShoppingAtcClick) {
      projectShoppingAtcAnalytics(output);
      return;
    }

    let stash = {
      component: PRODUCT_POD,
      item: items,
      pageType,
      section,
      sharedSection,
      target: ATC
    };

    let eventInfo = {
      eventName: CART_ADD_LOCATION
    };

    if (isArticle) {
      stash.pageType = 'article';
      stash.section = 'related products';
      stash.sharedSection = '';
    } else if (pageType === SEARCH) {
      stash.pageType = SEARCH_RESULTS;
    } else if (pageType === BROWSE) {
      stash.pageType = PLP;
    }

    if (podType === 'horizontal') {
      stash.section = 'listview';
      stash.sharedSection = '';
      stash.pageType = isSearchOrBrowse ? stash.pageType : COLLECTION_PAGETYPE_LIST_VIEW;
    } else if (podType === 'sidebyside') {
      stash.section = '';
      stash.sharedSection = '';
      if (!isSearchOrBrowse) {
        stash.component = '';
        stash.pageType = COLLECTION_PAGETYPE_INTEGRATED_BRANDS;
      }
    }

    if (isIRG(path)) {
      eventInfo.eventName = RECS_CART_ADDITION_INFO;
      eventInfo.eventMode = STASH;
      stash.section = 'dyn prod rec';
      stash.sharedSection = NOT_APPLICABLE;
      stash.recommendation = {
        recommendationType: 'irg',
        pageType,
        displayPosition: items?.[0]?.index,
        anchorProductSku: pageItemId,
        featureVersion: NOT_APPLICABLE,
        strategy: irgSectionName || NOT_APPLICABLE,
        recommendationVersion: NOT_APPLICABLE,
        recommendedProduct: NOT_APPLICABLE,
        interval: NOT_APPLICABLE
      };
    }

    if (isSpaceCollection || isCollection) {
      stash.pageType = `collections:${getCollectionPageType({ type: collectionType })}`;
      stash.sharedSection = null;
      stash.section = isSpaceCollection ? COLLECTION_PAGETYPE_SPACE : 'gridview';
      stash.item = items.map((item) => ({
        index: '',
        productId: item.productId
      }));
      extraEventData.eventMode = STASH;
      if (eventOriginatedFrom(CURATION, path)
        || eventOriginatedFrom(VISUAL_RECS, path)) {
        stash.component = 'carousel';
      }
    }

    if (eventOriginatedFrom(RECOMMENDATIONS, path)
      || eventOriginatedFrom(SEARCH_RECOMMENDATION, path)
      || eventOriginatedFrom(SUPPLEMENTAL_RECOMMENDATIONS, path)) {
      const scheme = schemaName(path);
      const anchorProductSku = anchorProduct(path);
      const addtocartRecommendationEvent = {
        category: {
          primaryCategory: STASH
        },
        eventInfo: {
          eventName: RECS_CART_ADDITION_INFO
        },
        eventMode: STASH,
        stash: {
          component: PRODUCT_POD,
          item: items,
          pageType,
          strategy: STRATEGY,
          section: SECTION,
          target: ATC,
          recommendation: {
            recommendationType: getRecommendationType(path, scheme),
            pageType,
            displayPosition: output?.items?.[0]?.index,
            anchorProductSku: pageItemId || anchorProductSku,
            featureVersion: NOT_APPLICABLE,
            strategy: NOT_APPLICABLE,
            recommendationVersion: RECOMMENDATION_VERSION,
            recommendedProduct: NOT_APPLICABLE,
            interval: NOT_APPLICABLE
          }
        }
      };
      if (isHomepage) {
        addtocartRecommendationEvent.stash.pageType = MY_HOMEPAGE;
        addtocartRecommendationEvent.stash.recommendation.pageType = MY_HOMEPAGE;
      }
      digitalData.event = digitalData.event || [];
      digitalData.event.push(addtocartRecommendationEvent);
    }

    // this conditional handles the newer 'cart addition info' contract
    // use this block if your spec requires 'cart addition info' instead of 'cart add location'
    if (eventOriginatedFrom(SPECIAL_BUY_BANNER, path)) {
      stash.item = items.map((item) => ({
        productInfo: {
          sku: item.productId
        },
      }));
      eventInfo.eventName = CART_ADDITION_INFO;
    }

    if (completeTheLookRecommendationClick) {
      completeTheLookRecommendationsATCAnalytics(output);
    }

    if (eventOriginatedFrom(MORE_IN_THIS_COLLECTION, path)) {
      stash.section = 'brand collection';
    }

    if (pageType === MY_LIST_DETAILS_PAGE_TYPE) {
      stash.sharedSection = 'n/a';
      stash.section = 'n/a';
      stash.component = 'n/a';
    }

    if (isTableView && (digitalData?.page?.displayAttributes?.viewType === 'tableview')) {
      stash.section = TABLEVIEW;
    }

    if (productShelfAtcClick) {
      stash.pageType = CATEGORY_PAGE;
      stash.section = 'product shelf';
    }

    const ddoEvent = {
      eventInfo,
      category: {
        primaryCategory: STASH
      },
      stash,
      ...extraEventData
    };
    digitalData.event.push(ddoEvent);

    if (sponsoredProduct && sponsoredClickBeacon) {
      sponsoredTracking(sponsoredClickBeacon);
      cartRecsAnalytics(output);
    }
  });

  window.LIFE_CYCLE_EVENT_BUS.on('badge.clickthrough', ({ output = {} }) => {
    const { badges = [] } = output;
    let { pageType = null, dataSources } = getState() || {};
    let recommendationVersion = 'model1';

    if (pageType === SEARCH) {
      pageType = SEARCH_RESULTS;
    } else if (pageType === BROWSE) {
      pageType = PLP;
    } else if (pageType === COLLECTION) {
      const collectionType = dataSources?.collection?.metadata?.type;
      if (collectionType === 'Space') {
        pageType = `collections:${getCollectionPageType({ type: collectionType })}`;
        recommendationVersion = 'n/a';
      }
    }

    const ddoEvent = {
      stash: {
        badge: [
          {
            type: badges[0]?.label || badges[0]?.name || NOT_APPLICABLE,
            pageType,
            recommendationStrategy: 'n/a',
            recommendationVersion,
          }
        ],
      },
      eventInfo: {
        eventName: 'badge clickthrough'
      },
      category: {
        primaryCategory: 'stash'
      },
      eventMode: 'stash',
      isReported: true,
      sourceIndex: 1,
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('instant-recs-like.click', ({ output }) => {
    const { sku } = output || {};
    const { pageItemId = '' } = getState() || {};
    const instantRecommendationLikeEvent = {
      category: {
        primaryCategory: INTERACTION
      },
      eventInfo: {
        eventName: INSTANT_RECOMMENDATION
      },
      recommendation: {
        anchorProductSku: pageItemId,
        sku,
        status: LIKE
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(instantRecommendationLikeEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('instant-recs-dislike.click', ({ output }) => {
    const { sku } = output || {};
    const { pageItemId = '' } = getState() || {};
    const instantRecommendationDislikeEvent = {
      category: {
        primaryCategory: INTERACTION
      },
      eventInfo: {
        eventName: INSTANT_RECOMMENDATION
      },
      recommendation: {
        anchorProductSku: pageItemId,
        sku,
        status: DISLIKE
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(instantRecommendationDislikeEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('product-pod.see-similar-items', ({ output = {} }) => {
    let unfulfillableType = '';
    const { path, product } = output;
    const isSpaceCollection = eventOriginatedFrom(SHOP_BY_ROOM_COLLECTION, path);
    const oosOnline = isOutOfStockOnlinePod(product);
    const discontinued = isDiscontinued(product);
    if (oosOnline) {
      unfulfillableType = 'out of stock';
    } else if (discontinued) {
      unfulfillableType = 'discontinued';
    }
    if (isSpaceCollection) {
      const ddoEvent = {
        category: {
          primaryCategory: 'button submission'
        },
        eventInfo: {
          eventName: 'content module click'
        },
        pageType: 'collections:space'
      };

      ddoEvent.content = {
        widgets: [{
          action: 'expand',
          id: 'n/a',
          name: 'subcontent_decor',
          templates: 'zone b',
          component: 'vis sim',
          element: unfulfillableType
        }]
      };
      digitalData.event = digitalData.event || [];
      digitalData.event.push(ddoEvent);
    }
  });

  window.LIFE_CYCLE_EVENT_BUS.on('product-pod.compare', ({ output = {} }) => {
    const {
      podPageNumber,
      podPosition,
      podType,
      podPageResults,
      podAction,
      podInteractionNumber,
      podAnchorSku,
      sponsoredProduct
    } = output;

    const ddoEvent = {
      stash: {
        podPageNumber,
        podPosition,
        podType,
        podPageResults,
        podAction,
        podInteractionNumber,
        podAnchorSku,
        sponsoredProduct,
        customProduct: NOT_CUSTOM_CATALOG // TODO: Update this to get from product pod once B2B CPO is implemented
      },
      eventInfo: {
        eventName: 'product pod click'
      },
      category: {
        primaryCategory: 'stash'
      },
      eventMode: 'stash',
      isReported: true,
      sourceIndex: 1,
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('services-button.click', ({ output = {} }) => {
    const { eventName = '', formName = '' } = output;
    const ddoEvent = {
      eventInfo: {
        eventName
      },
      category: {
        primaryCategory: BUTTON_SUBMISSION
      },
      form: {
        formName,
        formType: 'installation services and repair'
      }
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });

  window.LIFE_CYCLE_EVENT_BUS.on('product-pod.swap', ({ output = {} }) => {
    const { packageCategory } = getState() || {};
    const { anchorItem, swapItemPosition } = output;
    if (packageCategory === 'gm') {
      digitalData.event = digitalData.event || [];
      digitalData.event.push({
        category: {
          primaryCategory: STASH
        },
        eventInfo: {
          eventName: RECOMMENDATION_EVENTNAME
        },
        eventMode: STASH,
        stash: {
          recommendation: {
            recommendationType: GEN_MERCH_PACKAGE,
            pageType: PACKAGES_PIP,
            displayPosition: swapItemPosition || NOT_APPLICABLE,
            anchorProductSku: anchorItem,
            strategy: NOT_APPLICABLE,
            recommendationVersion: NOT_APPLICABLE,
            featureVersion: NOT_APPLICABLE,
            interval: NOT_APPLICABLE
          }
        }
      });
    }
  });
};