import digitalData from 'digital-data';
import { getState } from '../analytics-state';
import { NOT_APPLICABLE, PIP, STASH } from '../constants';

export const fbr = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('feature-based-recommendations.click', ({ output = {} }) => {
    const { index, recommendedFeatureSet, recommendedProductSet } = output;
    const { pageItemId = '' } = getState() || {};
    const ddoEvent = {
      eventInfo: {
        eventName: 'recommendation pip load'
      },
      category: {
        primaryCategory: STASH
      },
      eventMode: STASH,
      stash: {
        recommendation: {
          recommendationType: 'fbr',
          pageType: PIP,
          displayPosition: index + 1,
          anchorProductSku: pageItemId,
          strategy: NOT_APPLICABLE,
          recommendationVersion: recommendedProductSet,
          featureVersion: recommendedFeatureSet,
          interval: NOT_APPLICABLE
        }
      }
    };
    digitalData.event.push(ddoEvent);
  });
};