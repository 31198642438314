import digitalData from 'digital-data';
import { getState, setState } from '../../../analytics-state';
import {
  SEARCH_RESULTS,
  PLP_GRID,
  SEARCH,
  PRODUCTS_AVAILABILITY,
  PAGINATION,
  NUCLEUS,
  NO_EVENT,
  FILTER,
  SORT
} from '../../../constants';
import {
  setAnalytics,
  makeEverythingLowerCase,
  getContentProducts,
  getPageTemplate,
  getABtest,
  getHeaderCrumb,
  getCurrentPage,
  shouldReInitAnalytics,
  getFilters,
  initializeFiltersState
} from '../../../thd-analytics-utils';
import * as util from './util.search.analytics';

export function search() {
  const searchInit = ({ output: searchModel } = {}) => {
    let noSearchResults = false;

    if (!searchModel) return;
    let { experienceName, pageTransitionEvent = NO_EVENT } = getState();

    if (!experienceName) {
      experienceName = window?.experienceMetadata?.name;
    }

    const {
      searchReport = {},
      taxonomy = {},
      metadata = {},
      products = [],
      defaultPageSize = '',
      isLocalized = null,
    } = searchModel || {};

    if (isLocalized === false) return;

    if (searchReport?.totalProducts === 0 && !!searchReport?.keyword) {
      noSearchResults = true;
    }

    const onsiteSearchInfo = util.getOnsiteSearchInfo({ searchModel, experienceName });
    const breadCrumbs = util.getBrowseSearchBreadCrumb({ taxonomy });
    let pageLoadEvent = onsiteSearchInfo?.numberOfResults === '0' ? 'no search results' : SEARCH_RESULTS;

    if (noSearchResults) {
      pageLoadEvent = SEARCH_RESULTS; // seems redundant with line 53, remove later?
    }

    const {
      startIndex,
      pageSize,
      sortBy
    } = searchReport || {};

    const currentPage = getCurrentPage(startIndex, pageSize, defaultPageSize);
    if (pageTransitionEvent === PAGINATION) pageLoadEvent = PAGINATION;

    setState({
      ...getState(),
      pageTransitionEvent: undefined,
      currentPage,
      pageSize,
      sortBy,
      filters: initializeFiltersState({ appliedDimensions: searchModel?.appliedDimensions })
    });

    digitalData.content = {
      ...digitalData.content,
      product: getContentProducts(products)
    };

    if (!shouldReInitAnalytics(pageTransitionEvent)) {
      if (pageTransitionEvent === FILTER) {
        LIFE_CYCLE_EVENT_BUS.trigger('filters-refinements.publish', {
          breadCrumbs: taxonomy?.breadCrumbs,
          searchReport,
          metadata
        });
      } else if (pageTransitionEvent === SORT) {
        LIFE_CYCLE_EVENT_BUS.trigger('sort-refinements.publish');
      }
      return;
    }

    digitalData.page = {
      pageInfo: {
        abTest: getABtest({ experienceName, breadCrumbs, isCategory: false }),
        pageName: noSearchResults ? 'no search results' : SEARCH_RESULTS,
        pageLoadEvent,
        pageType: SEARCH_RESULTS,
        pageTemplate: getPageTemplate(experienceName),
        platform: NUCLEUS
      },
      category: {
        headerCrumb: getHeaderCrumb(taxonomy?.breadCrumbs),
        primaryCategory: SEARCH,
        subCategory1: SEARCH,
        subCategory2: SEARCH
      },
      displayAttributes: {
        pageNumber: currentPage,
        productsAvailability: PRODUCTS_AVAILABILITY,
        sortBy,
        viewType: PLP_GRID
      },
      filter: getFilters({ appliedDimensions: searchModel?.appliedDimensions }),
      onsiteSearchInfo
    };

    digitalData.page.pageInfo = makeEverythingLowerCase(digitalData.page.pageInfo);
    digitalData.page.category = makeEverythingLowerCase(digitalData.page.category);
    digitalData.page.onsiteSearchInfo = makeEverythingLowerCase(digitalData.page.onsiteSearchInfo);

    setAnalytics(digitalData);
  };

  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('search.new-products', searchInit);
}