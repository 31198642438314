import { onCLS, onLCP, onFID } from 'web-vitals/attribution';
import { findDataComponent } from '../utils/performance-observer-helpers';

const getExperienceConfig = () => window.__EXPERIENCE_PROPS__.expConfiguration || {};

let pollCounter = 0;
const POLL_TIMEOUT = 3000;
const POLL_RETRIES = 3;
const CLS_DECIMALS = 3;

const addReportValue = ({ name, value }) => {
  if (typeof window !== 'undefined' && window.newrelic) {
    window.newrelic.setCustomAttribute(name, value);
  }
};

const getQuantumMetricId = () => {

  pollCounter += 1;

  const quantumMetricID = (
    typeof window.QuantumMetricAPI !== 'undefined'
    && typeof window.QuantumMetricAPI.getSessionID === 'function'
    && window.QuantumMetricAPI.getSessionID()
  ) || undefined;

  if (quantumMetricID) {
    window.newrelic.setCustomAttribute('quantumMetricId', quantumMetricID);
    return;
  }

  if (pollCounter > POLL_RETRIES) return;

  setTimeout(getQuantumMetricId, POLL_TIMEOUT);
};

let longTaskObserver;
let longTaskCount = 0;

const recordLongTasks = () => {
  longTaskObserver = new PerformanceObserver((list) => {
    const perfEntries = list.getEntries();
    longTaskCount += perfEntries.length;
  });
  longTaskObserver.observe({ entryTypes: ['longtask'] });
};

export const performanceObserver = () => {
  const perfObserverEnabled = getExperienceConfig().PERFORMANCE_OBSERVER_ENABLED === 'true';

  if (!perfObserverEnabled) return;

  onCLS((info) => {
    if (!info || !info.value) return;

    const { value, entries, attribution } = info;
    const { largestShiftTarget, largestShiftValue, largestShiftEntry } = attribution;

    addReportValue({ name: 'CLS-Value', value: value?.toFixed(CLS_DECIMALS) });
    addReportValue({ name: 'CLS-LargestShiftTarget', value: largestShiftTarget });
    addReportValue({ name: 'CLS-LargestShiftValue', value: largestShiftValue?.toFixed(CLS_DECIMALS) });

    const sourcesOuterHtml = [];
    const dataComponents = [];

    if (Array.isArray(entries)) {
      entries.forEach((entry) => {

        if (Array.isArray(entry.sources)) {
          entry.sources.forEach((source) => {
            if (source?.node) {
              const node = source.node;
              sourcesOuterHtml.push(node.outerHTML);

              const dataComponent = findDataComponent({ node });
              if (dataComponent && !dataComponents.includes(dataComponent)) {
                dataComponents.push(dataComponent);
              }
            }
          });
        }
      });

      addReportValue({ name: 'CLS-Components', value: dataComponents.join(', ') });
      addReportValue({ name: 'CLS-Elements', value: sourcesOuterHtml.filter((html) => html !== '').join(', ') });
    }

    if (!largestShiftEntry || !Array.isArray(largestShiftEntry.sources)) return;

    // eslint-disable-next-line no-restricted-syntax
    for (const { node } of largestShiftEntry.sources) {
      const dataComponent = findDataComponent({ node });
      if (dataComponent) {
        addReportValue({ name: 'CLS-LargestShift-Component', value: dataComponent });
        break;
      }
    }
  });

  onLCP((info) => {
    if (!info || !info.value) return;

    const { attribution } = info;
    const {
      lcpEntry, timeToFirstByte, resourceLoadDelay, resourceLoadTime, elementRenderDelay
    } = attribution;
    const { url, element, renderTime, loadTime } = lcpEntry;
    const lcp = renderTime || loadTime;

    addReportValue({ name: 'LCP-Url', value: url });
    addReportValue({ name: 'LCP-Value', value: lcp });
    addReportValue({ name: 'LCP-TTFB', value: timeToFirstByte });
    addReportValue({ name: 'LCP-Load-Delay', value: resourceLoadDelay });
    addReportValue({ name: 'LCP-Load-Time', value: resourceLoadTime });
    addReportValue({ name: 'LCP-Render-Delay', value: elementRenderDelay });

    if (element) {
      const dataComponent = findDataComponent({ node: element });
      addReportValue({ name: 'LCP-Component', value: dataComponent });
      addReportValue({ name: 'LCP-Element', value: element.outerHTML });
    }
  });

  onFID((info) => {
    if (longTaskObserver) longTaskObserver.disconnect();
    addReportValue({ name: 'FID-Long-Task-Count', value: longTaskCount });

    if (!info || !info.value) return;

    const { value, attribution } = info;
    const { eventEntry, eventTarget, eventType, loadState } = attribution;

    addReportValue({ name: 'FID-Value', value });

    if (!eventEntry) return;

    addReportValue({ name: 'FID-Start', value: eventEntry.startTime });
    addReportValue({ name: 'FID-TargetElement', value: eventTarget });
    addReportValue({ name: 'FID-EventType', value: eventType });
    addReportValue({ name: 'FID-LoadState', value: loadState });

    const target = eventEntry.target;

    if (!target) return;

    const dataComponent = findDataComponent({ node: target });
    addReportValue({ name: 'FID-Component', value: dataComponent });
  });

  recordLongTasks();
  setTimeout(getQuantumMetricId, POLL_TIMEOUT);
};