import digitalData from 'digital-data';
import {
  OVERLAY, NOT_APPLICABLE, ORDER_SAMPLES
} from '../constants';

export const orderSample = () => {
  window.LIFE_CYCLE_EVENT_BUS.on('order-sample.click', () => {
    const ddoEvent = {
      eventInfo: {
        eventName: ORDER_SAMPLES
      },
      category: {
        primaryCategory: OVERLAY
      },
      event: [{
        subContent: {
          section: 'order fabric sample',
          component: NOT_APPLICABLE,
          target: NOT_APPLICABLE
        }
      }]
    };
    digitalData.event = digitalData.event || [];
    digitalData.event.push(ddoEvent);
  });
};