import digitalData from 'digital-data';
import { parseCategoryInfo } from './breadCrumbUtils';
import {
  makeEverythingLowerCase,
  setAnalytics
} from '../../../thd-analytics-utils';

export function landingPage() {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('landing-page.page-load', ({ output = {} }) => {
    const { breadCrumbLabels, pageType } = output;

    const categoryInfo = parseCategoryInfo(breadCrumbLabels);

    const {
      primaryCategory,
      subCategory1,
      subCategory2
    } = categoryInfo || {};

    digitalData.page = {
      pageInfo: {
        abTest: 'landing-page:version:gen2',
        pageLoadEvent: 'content',
        pageName: subCategory2,
        pageTemplate: 'default',
        pageType
      },
      category: {
        primaryCategory,
        subCategory1,
        subCategory2
      }
    };

    digitalData.page.pageInfo = makeEverythingLowerCase(digitalData.page.pageInfo);
    digitalData.page.category = makeEverythingLowerCase(digitalData.page.category);

    setAnalytics(digitalData);
  });
}