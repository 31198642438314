import digitalData from 'digital-data';
import { ERROR } from '../constants';

export const creditCard = () => {
  const trackEvent = (event) => {
    digitalData.event = digitalData.event || [];
    digitalData.event.push(event);
  };

  const actions = {
    postPageErrors: ({ output }) => {
      const {
        type, displayType, pageSection, message, code = 'n/a'
      } = output;
      const DDOEvent = {
        category: {
          primaryCategory: ERROR,
        },
        eventInfo: {
          eventName: 'post page error',
          error: [
            {
              type,
              displayType,
              pageSection,
              message,
              code,
            },
          ],
        },
      };
      trackEvent(DDOEvent);
    },
  };

  window.LIFE_CYCLE_EVENT_BUS.on('credit-card.actions.post-page-error', actions.postPageErrors);
};
